import "./info-section.component.styles.scss";

const InfoSection = (props) => {
  const parseData = (property, item, data) => {
    let currentObj;
    let finalObj;
    const splitProperty = property.split("/");
    const getObjProp = (obj, prop) => {
      // it's an array
      if (prop.includes("[")) {
        const splitted = prop.split("[");
        const indexNumber = parseInt(splitted[1].replace("]", ""));
        const objectName = splitted[0];
        return obj[objectName][indexNumber];
      }
      return obj[prop];
    };
    splitProperty.forEach((prop, index) => {
      if (currentObj === undefined) {
        currentObj = data;
      }
      finalObj = getObjProp(currentObj, prop);
      currentObj = finalObj;
    });
    if (item.transform) {
      return item.transform(currentObj);
    } else {
      return currentObj;
    }
  };

  const renderView = (config, data) => {
    if (!data) {
      return;
    }
    if (data.length === 0) {
      return;
    }
    if (config.type === "section") {
      return config.items.map((item, key) => {
        return (
          <div
            key={key}
            className=""
            style={{
              padding: "0.5rem 1.4rem",
              fontSize: "0.9rem",
            }}
          >
            <p className="font-weight-bold mb-2">{item.name}</p>
            <p className="mb-0">{parseData(item.property, item, data)}</p>
          </div>
        );
      });
    }
  };
  return (
    <>
      <h6>{props.config[0].title}</h6>
      <div className="d-flex flex-row justify-content-between border mb-4">
        {props.config.map((vc) => renderView(vc, props.data))}
      </div>
    </>
  );
};

export default InfoSection;
