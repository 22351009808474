import cloneDeep from "lodash.clonedeep";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Table from "../../components/ui/table/table.component";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import useSubmit from "../../services/useSubmit";
import {
  deNormalizeResponse,
  formConfig,
  pageConfig,
} from "./master-errands.config";

const NewMasterErrand = () => {
  const api = useApi();
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [variantFormData, setVariantFormData] = useState({});
  const [tableDataQuantity, setTableDataQuantity] = useState(1);
  const [schema, setSchema] = useState({});
  const [variantSchema, setVariantSchema] = useState({});
  const [variantUiSchema, setVariantUiSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const { submit } = useSubmit(deNormalizeResponse, pageConfig.apiRoute);
  const [finishedProducts, setFinishedProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleSubmit = () => {
    const normalizedData = {
      mast_err_number: formData.mast_err_number,
      mast_err_numb_origin: formData.mast_err_numb_origin,
      partner: formData.partner,
      truck: formData.truck,
      delivery_date: formData.delivery_date,
      products: [
        {
          product: formData.products,
          quantity: tableDataQuantity,
          composition: formData.composition,
          variant: variantFormData.variant,
        },
      ],
    };

    let materials = {
      body_part_material: "",
      seat_part_material: "",
      backrest_part_material: "",
      headrest_part_material: "",
      extra_part_material: "",
    };

    if (variantFormData.variant === "A") {
      for (const iterator in materials) {
        materials[iterator] = variantFormData["Kompletna presvlaka"];
      }
    }
    if (variantFormData.variant === "B") {
      materials.body_part_material = variantFormData["Korpus i nasloni"];
      materials.backrest_part_material = variantFormData["Korpus i nasloni"];
      materials.headrest_part_material = variantFormData["Korpus i nasloni"];
      materials.extra_part_material = variantFormData["Korpus i nasloni"];
      materials.seat_part_material = variantFormData["Sedalni deo"];
    }
    if (variantFormData.variant === "C") {
      materials.body_part_material = variantFormData["Korpus"];
      materials.backrest_part_material = variantFormData["Korpus"];
      materials.headrest_part_material = variantFormData["Glavonaslon"];
      materials.extra_part_material = variantFormData["Korpus"];
      materials.seat_part_material = variantFormData["Sedalni deo"];
    }

    normalizedData.products[0] = {
      ...normalizedData.products[0],
      ...materials,
      furn_legs: formData.furn_legs,
      special_case: variantFormData.specialCase,
      spec_case_text_one: variantFormData.spec_case_text_one,
      spec_case_text_two: variantFormData.spec_case_text_two,
      spec_case_text_three: variantFormData.spec_case_text_three,
    };
    submit({ formData: { ...normalizedData } });
  };

  React.useEffect(() => {
    const getId = async () => {
      let response = await api.get(`${API_ROUTES.masterErrands.generateId}`);
      setFormData({ ...formData, mast_err_number: response.mast_err_numb });
    };

    const getSingle = async () => {
      let res = await api.get(
        `${API_ROUTES[pageConfig.apiRoute].single}?id=${id}`
      );
      return res;
    };

    const getFinishedProducts = async () => {
      let res = await api.get(`${API_ROUTES.products.finished}`);
      let mapped = res.map((type) => {
        return { label: type.product_name, value: type.id };
      });
      let partners = await api.get(`${API_ROUTES.partners.all}`);
      let mappedPartners = partners.map((partner) => {
        return { label: partner.name, value: partner.id };
      });
      let materials = await api.get(`${API_ROUTES.products.materials}`);
      let mappedMaterials = materials.map((material) => {
        return { label: material.product_name, value: material.id };
      });

      let furnLegs = await api.get(`${API_ROUTES.products.furnLegs}`);
      let mappedLegs = furnLegs.map((leg) => {
        return { label: leg.product_name, value: leg.id };
      });

      let trucks = await api.get(`${API_ROUTES.trucks.all}`);
      let mappedTrucks = trucks.map((truck) => {
        return { label: truck.brand, value: truck.id };
      });

      formConfig.uiSchema.products[`ui:options`].data = mapped;
      formConfig.uiSchema.furn_legs[`ui:options`].data = mappedLegs;
      formConfig.uiSchema.partner[`ui:options`].data = mappedPartners;
      formConfig.uiSchema.truck[`ui:options`].data = mappedTrucks;

      formConfig.productTypeUiSchema["Kompletna presvlaka"][`ui:options`].data =
        mappedMaterials;
      formConfig.productTypeUiSchema["Korpus i nasloni"][`ui:options`].data =
        mappedMaterials;
      formConfig.productTypeUiSchema["Korpus"][`ui:options`].data =
        mappedMaterials;
      formConfig.productTypeUiSchema["Sedalni deo"][`ui:options`].data =
        mappedMaterials;
      formConfig.productTypeUiSchema["Glavonaslon"][`ui:options`].data =
        mappedMaterials;

      setFinishedProducts(res);

      setSchema(formConfig.schema);
      setUiSchema(formConfig.uiSchema);
      setVariantSchema(formConfig.productTypeSchema);
      setVariantUiSchema(formConfig.productTypeUiSchema);
      return res;
    };
    const id =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (id !== "novi") {
      getSingle();
    } else {
      getId();
    }
    getFinishedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormChange = (formData) => {
    if (formData.products) {
      if (selectedProducts !== formData.products) {
        const selectedProductsArray = [];
        finishedProducts.forEach((product) => {
          if (product.id === formData.products) {
            selectedProductsArray.push(product);
          }
        });
        selectedProductsArray[0].quantity = tableDataQuantity;
        setSelectedProducts(selectedProductsArray);
      }
    }
    setFormData(formData);
  };

  const handleVariantFormChange = (formData) => {
    setVariantFormData(formData);
    return formData;
  };

  const handleTableDataChange = (tableData) => {
    if (tableData[0]) {
      const selected = cloneDeep(selectedProducts);
      let parsed = parseInt(tableData[0].quantity);
      if (isNaN(parsed)) {
        parsed = 1;
      }
      setTableDataQuantity(parsed);
      selected[0].quantity = parsed;
      setSelectedProducts(selected);
    }
  };
  return (
    <SingleLayout className="master-errand-page">
      <Form
        hideButtons
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={(e) => {
          handleFormChange(e.formData);
        }}
        disableValidation
      >
        <Table
          apiRoute={API_ROUTES[pageConfig.apiRoute]}
          singlePageRoute={pageConfig.singlePageRoute}
          topNavDisabled
          data={{
            original: selectedProducts,
            display: pageConfig.selectedProductsConfig,
          }}
          onDataChange={handleTableDataChange}
          actions={pageConfig.selectedProductsActions}
          pagination={pageConfig.paginationConfig}
          components={pageConfig.tableComponentConfig}
        />
        <Form
          className="variant-form"
          hideButtons
          schema={variantSchema}
          uiSchema={variantUiSchema}
          formData={variantFormData}
          onChange={(e) => {
            handleVariantFormChange(e.formData);
          }}
        ></Form>
        <div className={`col d-flex justify-content-end`}>
          <button
            onClick={handleSubmit}
            className="ripple ripple-surface btn btn-primary m-2"
          >
            Sačuvaj
          </button>
          <div
            onClick={history.goBack}
            className="ripple ripple-surface btn btn-danger m-2"
          >
            Otkaži
          </div>
        </div>
      </Form>
    </SingleLayout>
  );
};

export default NewMasterErrand;
