import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { routesConfig } from "../../routes.config";

const Breadcrumb = () => {
  const [breadcrumbConfig, setBreadcrumbConfig] = React.useState([]);
  const history = useHistory();

  React.useEffect(() => {
    const breadcrumb = [];
    const pageRoute = history.location.pathname.split("/").filter((route) => {
      return route.length !== 0;
    });
    routesConfig.forEach((_) => {
      const configRoute = _.path.split("/").filter((route) => {
        return route.length !== 0;
      });
      if (pageRoute.length === configRoute.length) {
        if (pageRoute[0] === configRoute[0]) {
          for (let i = 0; i < pageRoute.length; i++) {
            const element = pageRoute[i];
            if (i === 0) {
              breadcrumb.push({
                path: `/${element}`,
                name: _.headerOptions.displayName,
                active: false,
              });
            }
            if (i === 1) {
              if (element !== "view") {
                breadcrumb.push({
                  path: `${breadcrumb[breadcrumb.length - 1].path}/${element}`,
                  name: element,
                  active: false,
                });
              }
            }
            if (i === 2) {
              breadcrumb.push({
                path: `${breadcrumb[breadcrumb.length - 1].path}/${element}`,
                name: element,
                active: false,
              });
            }
          }
        }
      }
    });
    breadcrumb[breadcrumb.length - 1].active = true;
    if (breadcrumb[breadcrumb.length - 1].name === "novi") {
      breadcrumb[breadcrumb.length - 1].name = "Napravi novi";
    }
    setBreadcrumbConfig(breadcrumb);
  }, [history.location.pathname]);

  return (
    <div className="pb-2 pt-2" style={{ paddingLeft: "1.5rem" }}>
      <MDBBreadcrumb className="m-0">
        {breadcrumbConfig.map((bc, key) => (
          <MDBBreadcrumbItem active={bc.active} key={key}>
            {bc.active ? (
              bc.name
            ) : (
              <Link className="p-0" to={bc.path}>
                {bc.name}
              </Link>
            )}
          </MDBBreadcrumbItem>
        ))}
      </MDBBreadcrumb>
    </div>
  );
};

export default Breadcrumb;
