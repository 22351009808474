const pageConfig = {
  apiRoute: "masterErrands",
  singlePageRoute: "mrni",
  productsTableConfig: [
    {
      name: "Ime ",
      render: "product/product_name",
    },
    {
      name: "Sifra",
      render: "product/barcode",
    },
    {
      name: "Kolicina",
      render: "quantity",
    },
  ],
};

const formConfig = {
  schema: {
    type: "object",
    properties: {
      truck: {
        title: "Kamion",
        type: "string",
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    truck: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
};

const normalizeResponse = (res) => {
  return res;
};

const deNormalizeResponse = (res) => {
  return res;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
