import cloneDeep from "lodash.clonedeep";
import { MDBTableBody } from "mdb-react-ui-kit";
import React from "react";
import TableActions from "./table.actions.component";

const NoData = (props) => (
  <MDBTableBody>
    <tr>
      <td
        colSpan={props.headerSize + 1 + (props.components ? 1 : 0)}
        className="text-center"
      >
        Nema podataka
      </td>
    </tr>
  </MDBTableBody>
);

const TableBodyComponent = (props) => {
  if (props.data === undefined || props.data.length === 0) {
    return <NoData {...props} />;
  }

  const handleComponentChange = (e, attribute, row) => {
    let newData = cloneDeep(props.data);
    newData.forEach((dataPoint) => {
      if (dataPoint.id === row.id) {
        dataPoint[attribute] = e.target.value;
        row[attribute] = e.target.value;
      }
    });
    props.handleDataChange(newData, row, attribute);
  };

  const getColumns = (row) => {
    if (row) {
      const array = [];
      for (const iterator of props.displayedData) {
        const path = iterator.render.split("/");
        if (iterator.component) {
          array.push(
            iterator.component.render({
              name: iterator.component.attribute,
              attribute: iterator.component.attribute,
              callback: handleComponentChange,
              row,
              value: row[iterator.render] ? row[iterator.render] : 1,
            })
          );
        } else {
          if (row.hasOwnProperty(iterator.render)) {
            array.push(row[iterator.render]);
          }
          if (path.length === 2) {
            if (row[path[0]]) {
              if (row[path[0]][path[1]]) {
                array.push(row[path[0]][path[1]]);
              }
            }
          }
        }
      }
      return array;
    }
  };
  return (
    <MDBTableBody>
      {props.data.map((row, key) => {
        return (
          <tr key={key}>
            {getColumns(row).map((column, key) => {
              return <td key={key}>{column}</td>;
            })}
            <TableActions
              actions={props.actions}
              handleCustomAction={props.handleCustomAction}
              handleDelete={props.handleDelete}
              handleDownload={props.handleDownload}
              handlePrint={props.handlePrint}
              handleEdit={props.handleEdit}
              handleView={props.handleView}
              id={row.id}
            />
          </tr>
        );
      })}
    </MDBTableBody>
  );
};

export default TableBodyComponent;
