import { useContext, useState } from "react";
import Form from "../../components/widgets/_form/form";
import logo from "../../images/logo.png";
import API_ROUTES from "../../layers/api/api.routes";
import useApi from "../../services/useApi";
import { globalContext } from "../../store/global-store";
import "./auth.page.styles.scss";

let formConfig = {
  schema: {
    type: "object",
    required: ["username", "password"],
    properties: {
      username: {
        title: "Username",
        type: "string",
        minLength: 3,
      },
      password: {
        title: "Šifra",
        type: "string",
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],

    username: { required: true },
    password: {
      "ui:widget": "password",
    },
  },
};

const Auth = () => {
  const api = useApi();
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(undefined);
  const { dispatch } = useContext(globalContext);
  const handleSubmit = async () => {
    try {
      const data = await api.post(API_ROUTES.login.basic, formData);
      if (data) {
        const user = {
          email: data.email,
          privilege: data.privilege,
        };
        dispatch({ type: "SET_USER", payload: user });
        setError(undefined);
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-container">
      <div className="form-container border">
        <img src={logo} alt="company logo"></img>
        {error && (
          <p className="font-weight-bold">
            Pogrešni podaci, unesite ispravne podatke i pokušajte ponovo.
          </p>
        )}
        <Form
          hideButtons
          schema={formConfig.schema}
          uiSchema={formConfig.uiSchema}
          formData={formData}
          onChange={(e) => {
            setFormData(e.formData);
          }}
          disableValidation
        >
          <div className={`buttons col d-flex justify-content-start`}>
            <button
              onClick={() => {
                handleSubmit();
              }}
              className="ripple ripple-surface btn btn-primary m-0"
            >
              Login
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Auth;
