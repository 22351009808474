import { MDBIcon } from "mdb-react-ui-kit";
import React from "react";

const TableActions = (props) => {
  if (!props.actions) {
    return null;
  }

  const determineActionName = (type, icon) => {
    switch (type) {
      case "delete":
        return "trash-alt";
      case "edit":
        return "edit";
      case "view":
        return "eye";
      case "print":
        return "print";
      case "download":
        return "download";
      case "custom":
        return icon;
      default:
        return "question";
    }
  };
  const determineActionCallback = (type, id) => {
    switch (type) {
      case "delete":
        return props.handleDelete(id);
      case "edit":
        return props.handleEdit(id);
      case "view":
        return props.handleView(id);
      case "download":
        return props.handleDownload(id);
      case "print":
        return props.handlePrint(id);
      case "custom":
        return props.handleCustomAction(id);
      default:
        return "question";
    }
  };

  return (
    <td>
      {props.actions
        .sort((a, b) => a.order - b.order)
        .map((action, key) => {
          return (
            <MDBIcon
              key={key}
              fas
              icon={determineActionName(action.name, action.icon)}
              className="fa-lg table-action"
              onClick={() => determineActionCallback(action.name, props.id)}
            />
          );
        })}
    </td>
  );
};

export default TableActions;
