import React from "react";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/global/error";
import Routes from "./components/routes";
import { ErrorProvider } from "./services/useError";
import { LoadingProvider } from "./services/useLoading";
import { GlobalStore } from "./store/global-store";
import "./styles/App.scss";

function App() {
  return (
    <GlobalStore>
      <ErrorBoundary>
        <ErrorProvider>
          <LoadingProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </LoadingProvider>
        </ErrorProvider>
      </ErrorBoundary>
    </GlobalStore>
  );
}

export default App;
