const MAIN_URL = "https://dodic.ididit.mystableservers.com/api";

const API_ROUTES = {
  trucks: {
    single: `${MAIN_URL}/truck`,
    all: `${MAIN_URL}/trucks`,
    add: `${MAIN_URL}/add-truck`,
    update: `${MAIN_URL}/update-truck`,
    delete: `${MAIN_URL}/delete-truck`,
    load: `${MAIN_URL}/load`,
    unload: `${MAIN_URL}/add-unload`,
    placeLoad: `${MAIN_URL}/place-load`,
  },
  sectors: {
    single: `${MAIN_URL}/sector`,
    all: `${MAIN_URL}/sectors`,
    add: `${MAIN_URL}/add-sector`,
    update: `${MAIN_URL}/update-sector`,
    delete: `${MAIN_URL}/delete-sector`,
  },
  operations: {
    single: `${MAIN_URL}/operation`,
    all: `${MAIN_URL}/operations`,
    add: `${MAIN_URL}/add-operation`,
    update: `${MAIN_URL}/update-operation`,
    delete: `${MAIN_URL}/delete-operation`,
  },
  productTypes: {
    single: `${MAIN_URL}/product-type`,
    all: `${MAIN_URL}/product-types`,
    add: `${MAIN_URL}/add-product-type`,
    update: `${MAIN_URL}/update-product-type`,
    delete: `${MAIN_URL}/delete-product-type`,
  },
  workers: {
    single: `${MAIN_URL}/worker`,
    all: `${MAIN_URL}/workers`,
    add: `${MAIN_URL}/add-worker`,
    update: `${MAIN_URL}/update-worker`,
    delete: `${MAIN_URL}/delete-worker`,
  },
  products: {
    single: `${MAIN_URL}/product`,
    all: `${MAIN_URL}/products`,
    add: `${MAIN_URL}/add-product`,
    update: `${MAIN_URL}/update-product`,
    delete: `${MAIN_URL}/delete-product`,
    finished: `${MAIN_URL}/products-finished`,
    materials: `${MAIN_URL}/products-materials`,
    furnLegs: `${MAIN_URL}/products-furn-legs`,
  },
  masterErrands: {
    single: `${MAIN_URL}/master-errand`,
    all: `${MAIN_URL}/master-errands`,
    add: `${MAIN_URL}/add-master-errand`,
    generateId: `${MAIN_URL}/generate-master-errand-number`,
    update: `${MAIN_URL}/update-master-errand`,
  },
  relatedProducts: {
    single: `${MAIN_URL}/related-product`,
    all: `${MAIN_URL}/related-products`,
    add: `${MAIN_URL}/add-related-product`,
    delete: `${MAIN_URL}/remove-related-product`,
  },
  partners: {
    all: `${MAIN_URL}/partners`,
  },
  errands: {
    all: `${MAIN_URL}/errands`,
    single: `${MAIN_URL}/errand`,
    update: `${MAIN_URL}/update-errand`,
    addItem: `${MAIN_URL}/add-errand-item`,
    editItem: `${MAIN_URL}/update-errand-item`,
    deleteItem: `${MAIN_URL}/delete-errand-item`,
  },
  workerPerformance: {
    all: `${MAIN_URL}/workers-performances`,
    add: `${MAIN_URL}/add-worker-performance`,
  },
  status: {
    all: `${MAIN_URL}/statuses`,
  },
  login: {
    basic: `${MAIN_URL}/login`,
  },
};

export default API_ROUTES;
