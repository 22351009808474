import cloneDeep from "lodash.clonedeep";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Table from "../../components/ui/table/table.component";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import useSubmit from "../../services/useSubmit";
import {
  deNormalizeResponse,
  formConfig,
  normalizeResponse,
  packagesFormConfig,
  pageConfig,
} from "./products.config";

const SingleProducts = () => {
  const api = useApi();
  const location = useLocation();
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [packagesFormData, setPackagesFormData] = useState({});
  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [packagesSchema] = useState(packagesFormConfig.schema);
  const [packagesUiSchema, setPackagesUiSchema] = useState(
    packagesFormConfig.uiSchema
  );
  const { submit } = useSubmit(deNormalizeResponse, pageConfig.apiRoute);
  const id =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  React.useEffect(() => {
    const getSingle = async () => {
      let res = await api.get(
        `${API_ROUTES[pageConfig.apiRoute].single}?id=${id}`
      );
      res = normalizeResponse(res);
      setFormData(res);
      setPackagesFormData({
        num_of_packages: res.num_of_packages,
        product_packages: res.product_packages,
      });
      return res;
    };

    const getProductTypes = async () => {
      let res = await api.get(`${API_ROUTES.productTypes.all}`);
      let mapped = res.map((type) => {
        return { label: type.type_name, value: type.id };
      });

      formConfig.uiSchema.product_type[`ui:options`].data = mapped;

      setSchema(formConfig.schema);
      setUiSchema(formConfig.uiSchema);
      return res;
    };
    const id =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (id !== "novi") {
      getSingle();
    }
    getProductTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    await submit({ formData: { ...formData, ...packagesFormData } });
  };

  useEffect(() => {
    if (packagesFormData.num_of_packages) {
      const value = cloneDeep(packagesUiSchema);
      value.product_packages["ui:options"].renderNum =
        packagesFormData.num_of_packages;
      setPackagesUiSchema(value);
    }
  }, [packagesFormData]);

  return (
    <SingleLayout className="products-page">
      <Form
        className="product-form"
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={(e) => {
          setFormData(e.formData);
        }}
        hideButtons
      >
        {id === "novi" ? null : (
          <Table
            handleEdit={(id) => {
              history.push(`/proizvodi/${id}`);
              window.location.reload();
            }}
            handleDelete={(id) => {}}
            apiRoute="relatedProducts"
            singlePageRoute={pageConfig.singlePageRoute}
            title="Povezani proizvodi"
            data={{
              original: formData.related_products,
              display: pageConfig.relatedProductsConfig,
            }}
            actions={pageConfig.relatedProductsActionsConfig}
          />
        )}
      </Form>
      <Form
        className="packages-form"
        onChange={(e) => {
          setPackagesFormData(e.formData);
        }}
        onSubmit={handleSubmit}
        schema={packagesSchema}
        uiSchema={packagesUiSchema}
        formData={packagesFormData}
        disableValidation
      />
    </SingleLayout>
  );
};

export default SingleProducts;
