import cloneDeep from "lodash.clonedeep";

const pageConfig = {
  apiRoute: "products",
  singlePageRoute: "proizvodi",
  tableConfig: [
    {
      name: "Naziv",
      render: "product_name",
    },
    {
      name: "Kod",
      render: "code",
    },
    {
      name: "Bar kod",
      render: "barcode",
    },
  ],
  actionsConfig: [
    {
      name: "edit",
      order: 1,
    },
    {
      name: "delete",
      order: 2,
    },
  ],
  relatedProductsConfig: [
    {
      name: "Naziv",
      render: "product_name",
    },
    {
      name: "Kolicina",
      render: "product_quantity",
    },
  ],
  relatedProductsActionsConfig: [
    {
      name: "edit",
      order: 1,
    },
  ],
  searchConfig: { keys: ["product_name", "barcode", "code"] },
  paginationConfig: {
    perPage: 10,
    count: 5,
    range: 3,
  },
};

const formConfig = {
  schema: {
    type: "object",
    required: ["logik_id"],
    properties: {
      logik_id: { type: "string", title: "Logik ID" },
      product_name: {
        title: "Ime proizvoda",
        type: "string",
      },
      code: {
        title: "Kod proizvoda",
        type: "string",
      },
      barcode: {
        title: "Barkod proizvoda",
        type: "string",
      },
      unit: {
        title: "Jedinica mere",
        type: "string",
      },
      width: {
        title: "Širina",
        type: "number",
      },
      height: {
        title: "Visina",
        type: "number",
      },
      lenght: {
        title: "Dužina",
        type: "number",
      },
      m_3: {
        title: "Zapremina",
        type: "number",
      },
      color: {
        title: "Boja",
        type: "string",
      },
      with_stretching: {
        title: "Sa elastinom",
        type: "boolean",
        default: false,
      },
      is_material: {
        title: "Materijal",
        type: "boolean",
        default: false,
      },
      is_furn_legs: {
        title: "Nogice",
        type: "boolean",
        default: false,
      },
      assembly_time: {
        title: "Vreme sklapanja",
        type: "number",
      },
      manuf_name: {
        title: "Ime proizvodnje",
        type: "string",
      },
      manuf_series: {
        title: "Serija proizvodnje",
        type: "string",
      },
      manuf_code: {
        title: "Kod proizvodnje",
        type: "string",
      },
      origin_country: {
        title: "Zemlja porekla",
        type: "string",
      },
      transport_country: {
        title: "Zemlja izvoza",
        type: "string",
      },
      mass: {
        title: "Masa",
        type: "string",
      },
      mass_unit: {
        title: "Jedinica mase",
        type: "string",
      },
      gross_mass: {
        title: "Ukupna masa",
        type: "string",
      },
      product_type: {
        title: "Tip proizvoda",
        type: "string",
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    product_type: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "type_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
};

const packagesFormConfig = {
  schema: {
    type: "object",
    properties: {
      num_of_packages: { type: "number", title: "Ukupno paketa", min: 0 },
      product_packages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            package_num: {
              type: "string",
              title: "Broj paketa",
            },
            m3: {
              type: "number",
              title: "Zapremina",
            },
          },
        },
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    num_of_packages: {
      "ui:options": {
        type: "number",
        min: 0,
        max: 6,
      },
    },
    product_packages: {
      "ui:widget": "ObjectArrayWidget",
      "ui:options": {
        package_num: {
          "ui:readonly": true,
        },
        m3: {
          type: "float",
        },
        renderNum: 0,
        removable: false,
        orderable: false,
        addable: false,
      },
    },
  },
};

const normalizeResponse = (res) => {
  let resp = cloneDeep(res);
  resp.logik_id = res.logikId.toString();
  resp.width = parseInt(res.width);
  resp.height = parseInt(res.height);
  resp.lenght = parseInt(res.lenght);
  resp.m_3 = parseInt(res.m_3);
  resp.with_stretching = res.with_stretching === 1 ? true : false;
  resp.is_material = res.is_material === 1 ? true : false;
  resp.is_furn_legs = res.is_furn_legs === 1 ? true : false;
  if (resp.product_packages && resp.product_packages.length > 0) {
    let array = [];
    let obj = {
      m3: "0",
      package_num: "0",
    };
    resp.product_packages.forEach((pack) => {
      obj.m3 = pack.m3_product_package;
      obj.package_num = pack.num_of_package.charAt(0);
      array.push(obj);
      obj = {
        m3: "0",
        package_num: "0",
      };
    });
    resp.product_packages = array;
  }
  return resp;
};

const deNormalizeResponse = (res) => {
  let resp = cloneDeep(res);
  resp.type = resp.product_type;
  if (resp.product_packages && resp.product_packages.length > 0) {
    resp.product_packages.forEach((pack, index) => {
      pack.m3 = parseFloat(pack.m3);
      pack.package_num = index + 1;
    });
  }
  resp.product_packages = resp.product_packages.splice(0, resp.num_of_packages);
  return resp;
};

export {
  pageConfig,
  packagesFormConfig,
  formConfig,
  normalizeResponse,
  deNormalizeResponse,
};
