import { useError } from "../../services/useError";
import GeneralLayout from "../_general/general.layout";
import "./single.layout.styles.scss";

const SingleLayout = (props) => {
  const { hasErrors, errors } = useError();

  const renderErrors = () => {
    return errors.map((err, key) => (
      <div key={key}>
        {err.name}:{err.message}
      </div>
    ));
  };

  return (
    <GeneralLayout>
      <div
        className={`single-layout ${props.className ? props.className : ""}`}
      >
        {hasErrors ? renderErrors() : null}
        {props.children}
      </div>
    </GeneralLayout>
  );
};

SingleLayout.propTypes = {};

export default SingleLayout;
