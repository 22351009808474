import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Table from "../../components/ui/table/table.component";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import { pageConfig } from "./master-errands.config";

let formConfig = {
  schema: {
    type: "object",
    properties: {
      mast_err_number: {
        title: "Broj master radnog naloga",
        type: "string",
        minLength: 3,
      },
      mast_err_numb_origin: {
        title: "Originalni broj naloga",
        type: "string",
      },
      partner: {
        title: "Partner",
        type: "string",
      },
      status: {
        title: "Status",
        type: "string",
      },
      truck: {
        title: "Kamion",
        type: "string",
      },
      delivery_date: {
        title: "Rok izrade",
        type: "string",
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    mast_err_number: {
      "ui:readonly": true,
    },
    delivery_date: {
      "ui:widget": "date",
      type: "date",
    },
    truck: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "value",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "brand",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    partner: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "value",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    status: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "value",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
};

const specialCaseViewConfig = [
  {
    type: "section",
    items: [
      {
        name: "Specijalka",
        property: "mast_errand/mast_err_items[0]/special_case",
        transform: (data) => (data === 0 ? "Ne" : "Da"),
      },
      {
        name: "Specijalka 1",
        property: "mast_errand/mast_err_items[0]/spec_case_text_one",
      },
      {
        name: "Specijalka 2",
        property: "mast_errand/mast_err_items[0]/spec_case_text_two",
      },
      {
        name: "Specijalka 3",
        property: "mast_errand/mast_err_items[0]/spec_case_text_three",
      },
    ],
  },
  { type: "table" },
];

const EditMasterErrand = () => {
  const api = useApi();
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [errandData, setErrandData] = useState([]);
  const [masterErrandData, setMasterErrandData] = useState(undefined);

  const handleSubmit = async () => {
    let splitDate = formData.delivery_date.split("-");
    const res = await api.put(API_ROUTES.masterErrands.update, {
      mast_err_id: masterErrandData.id,
      mast_err_numb_origin: formData.mast_err_numb_origin,
      partner: formData.partner,
      status: formData.status,
      truck: formData.truck,
      delivery_date: `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`,
      _method: "PUT",
    });
    if (res.message === "Successfully updated master errand!") {
      history.goBack();
    }
  };

  React.useEffect(() => {
    const getSingle = async () => {
      const id = location.pathname.split("/")[2];
      let res = await api.get(
        `${API_ROUTES[pageConfig.apiRoute].single}?id=${id}`
      );

      const normalizeDate = (date) => {
        const splitted = date.split(".");
        if (splitted.length === 0) {
          return date;
        }
        return `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
      };

      const normalizedFormData = {
        mast_err_number: res.mast_errand.mast_errand_number,
        mast_err_numb_origin: res.mast_errand.mast_errand_number_origin,
        truck: res.mast_errand.truck.id,
        status: res.mast_errand.status_id,
        partner: res.mast_errand.partner.id,
        delivery_date: normalizeDate(res.mast_errand.delivery_date),
      };

      setFormData(normalizedFormData);
      setMasterErrandData(res.mast_errand);
      setErrandData(res.errands.data);
      return res;
    };

    const getData = async () => {
      let partners = await api.get(`${API_ROUTES.partners.all}`);
      let mappedPartners = partners.map((partner) => {
        return { label: partner.name, value: partner.id };
      });

      let trucks = await api.get(`${API_ROUTES.trucks.all}`);
      let mappedTrucks = trucks.map((truck) => {
        return { label: truck.brand, value: truck.id };
      });

      let statuses = await api.get(`${API_ROUTES.status.all}`);
      let mappedStatuses = statuses.map((status) => {
        return { label: status.status_name, value: status.id };
      });

      formConfig.uiSchema.partner[`ui:options`].data = mappedPartners;
      formConfig.uiSchema.truck[`ui:options`].data = mappedTrucks;
      formConfig.uiSchema.status[`ui:options`].data = mappedStatuses;

      setSchema(formConfig.schema);
      setUiSchema(formConfig.uiSchema);
    };
    getSingle();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormChange = (formData) => {
    setFormData(formData);
  };

  const parseData = (property, item) => {
    let currentObj;
    let finalObj;
    const splitProperty = property.split("/");
    const getObjProp = (obj, prop) => {
      // it's an array
      if (prop.includes("[")) {
        const splitted = prop.split("[");
        const indexNumber = parseInt(splitted[1].replace("]", ""));
        const objectName = splitted[0];
        return obj[objectName][indexNumber];
      }
      if (obj[prop]) {
        return obj[prop];
      }
    };
    splitProperty.forEach((prop, index) => {
      if (currentObj === undefined) {
        currentObj = masterErrandData;
      }
      if (currentObj !== undefined) {
        finalObj = getObjProp(currentObj, prop);
        currentObj = finalObj;
      }
    });
    if (item.transform) {
      return item.transform(currentObj);
    } else {
      return currentObj;
    }
  };

  const renderView = (vc) => {
    if (!errandData) {
      return;
    }
    if (errandData.length === 0) {
      return;
    }
    if (vc.type === "section") {
      return vc.items.map((item, key) => {
        return (
          <div
            key={key}
            className=""
            style={{
              padding: "0.5rem 1.4rem",
              fontSize: "0.9rem",
            }}
          >
            <p className="font-weight-bold mb-2">{item.name}</p>
            <p className="mb-0">{parseData(item.property, item)}</p>
          </div>
        );
      });
    }
  };
  return (
    <SingleLayout className="master-errand-page">
      <Form
        hideButtons
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={(e) => {
          handleFormChange(e.formData);
        }}
        disableValidation
      >
        <div className={`col d-flex justify-content-end`}>
          <button
            onClick={handleSubmit}
            className="ripple ripple-surface btn btn-primary m-2"
          >
            Izmeni
          </button>
          <div
            onClick={history.goBack}
            className="ripple ripple-surface btn btn-danger m-2"
          >
            Otkaži
          </div>
        </div>
        <Table
          className="mb-4"
          title="Proizvodi"
          apiRoute={API_ROUTES[pageConfig.apiRoute]}
          singlePageRoute={pageConfig.singlePageRoute}
          data={{
            original: masterErrandData?.mast_err_items,
            display: pageConfig.productsTableConfig,
          }}
        />
        <Table
          className="mb-4"
          title="Radni nalozi"
          apiRoute={API_ROUTES[pageConfig.apiRoute]}
          singlePageRoute={pageConfig.singlePageRoute}
          data={{
            original: errandData,
            display: pageConfig.errandsTableConfig,
          }}
        />
        <div className="d-flex flex-row justify-content-between border mb-4">
          {specialCaseViewConfig.map((vc) => renderView(vc))}
        </div>
      </Form>
    </SingleLayout>
  );
};

export default EditMasterErrand;
