import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import FloatingButton from "../../components/ui/floating.button/floating.button";
import LoadingComponent from "../../components/ui/loading/loading.component";
import { useLoading } from "../../services/useLoading";
import GeneralLayout from "../_general/general.layout";

const TableLayout = (props) => {
  const history = useHistory();
  const { isLoading } = useLoading();
  const goToRoute = () => {
    history.push({
      pathname: `/${props.singleRoute}/novi`,
    });
  };

  return (
    <GeneralLayout>
      {isLoading && <LoadingComponent />}
      {!isLoading && props.children}
      <FloatingButton onClick={goToRoute} />
    </GeneralLayout>
  );
};

TableLayout.propTypes = {
  singleRoute: PropTypes.string.isRequired,
};

export default TableLayout;
