import React from "react";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb.component";

const GeneralLayout = (props) => {
  return (
    <div className="mt-0">
      <Breadcrumb />
      {props.children}
    </div>
  );
};

export default GeneralLayout;
