import { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Auth from "../pages/auth/auth.page";
import { globalContext } from "../store/global-store";
import Header from "./global/header";
import { routesConfig } from "./routes.config";

const Routes = () => {
  const { globalState } = useContext(globalContext);
  const routeComponents = routesConfig.map(
    ({ exact, path, component }, key) => (
      <Route exact path={path} component={component} key={key} />
    )
  );
  return (
    <>
      {!globalState.user && <Route exact path={"/"} component={Auth} />}
      {globalState.user && (
        <>
          <Header />
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            {routeComponents}
          </Switch>
        </>
      )}
    </>
  );
};

export default Routes;
