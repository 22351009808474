const pageConfig = {
  apiRoute: "related-products",
};

const formConfig = {
  addSchema: {
    type: "object",
    properties: {
      related_product: {
        title: "Povezani proizvod",
        type: "string",
      },
      quantity: {
        title: "Količina",
        type: "number",
      },
    },
  },
  addUiSchema: {
    related_product: {
      "ui:widget": "customSelect",
      "ui:options": {
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },

  dataSchema: {
    type: "object",
    properties: {
      product: {
        title: "Proizvod",
        type: "string",
      },
    },
  },
  dataUiSchema: {
    "ui:order": ["*"],
    product: {
      "ui:widget": "customSelect",
      "ui:options": {
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
};

const normalizeResponse = (res) => {
  //   res.logik_id = res.logikId.toString();
  //   res.width = parseInt(res.width);
  //   res.height = parseInt(res.height);
  //   res.lenght = parseInt(res.lenght);
  //   res.m_3 = parseInt(res.m_3);
  //   res.with_stretching = res.with_stretching === "1" ? true : false;
  //   res.is_material = res.is_material === "1" ? true : false;
  return res;
};

const deNormalizeResponse = (res) => {
  //   res.type = res.product_type;
  return res;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
