import { MDBSpinner } from "mdb-react-ui-kit";
import React from "react";
import { useLoading } from "../../../services/useLoading";
import "./loading.component.styles.scss";

const LoadingComponent = () => {
  const { isLoading } = useLoading();
  return (
    isLoading && (
      <div className="loader-center">
        <MDBSpinner grow className="mx-1" color="primary" size="sm">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
        <MDBSpinner grow className="mx-1" color="secondary" size="sm">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
        <MDBSpinner grow className="mx-1" color="success" size="sm">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
        <MDBSpinner grow className="mx-1" color="light" size="sm">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
        <MDBSpinner grow className="mx-1" color="warning" size="sm">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
        <MDBSpinner grow className="mx-1" color="danger" size="sm">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
        <MDBSpinner grow className="mx-1" color="info" size="sm">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    )
    // <div>1</div>
  );
};

export default LoadingComponent;
