import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: "" };
  }

  componentDidCatch(error) {
    console.debug("ErrorBoundary", error);
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
