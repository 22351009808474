import React, { useState } from "react";
import Form from "../../components/widgets/_form/form";
import SingleLayout from "../../layouts/single/single.layout";
import useData from "../../services/useData";
import useSubmit from "../../services/useSubmit";
import {
  deNormalizeResponse,
  formConfig,
  normalizeResponse,
  pageConfig,
} from "./sectors.config";

const SingleSector = () => {
  const [formData, setFormData] = useState({});
  const { submit } = useSubmit(deNormalizeResponse, pageConfig.apiRoute);
  const { data } = useData(true, pageConfig.apiRoute, normalizeResponse);

  React.useEffect(() => {
    if (data !== undefined) {
      setFormData(data);
    }
  }, [data]);

  return (
    <SingleLayout>
      <Form
        schema={formConfig.schema}
        uiSchema={formConfig.uiSchema}
        formData={formData}
        onSubmit={submit}
        onChange={(e) => {
          setFormData(e.formData);
        }}
      />
    </SingleLayout>
  );
};

export default SingleSector;
