import cloneDeep from "lodash.clonedeep";
import React, { useEffect } from "react";
import TextWidget from "../text-widget/text.widget";

const ObjectArrayWidget = (props) => {
  const [value, setValue] = React.useState([]);
  const [numOfFields, setNumOfFields] = React.useState(0);

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  useEffect(() => {
    const number = props.options.renderNum;
    if (number >= 0) {
      setNumOfFields(number);
    }
  }, [props.options.renderNum]);

  const setAppropriateFieldValue = (object, key) => {
    const newValue = cloneDeep(value);
    if (newValue[key]) {
      newValue[key] = {
        ...newValue[key],
        ...object,
      };
    } else {
      newValue.push(object);
    }
    setValue(newValue);
  };

  const mapProperties = (properties, num) => {
    const getValue = (attribute) => {
      if (attribute === "package_num") {
        return `${num + 1}/${props.options.renderNum}`;
      }
      if (value[num]) {
        if (value[num][attribute]) {
          return value[num][attribute];
        }
      }
    };

    const render = [];
    for (const attr in properties) {
      render.push(
        <TextWidget
          key={`${num}-${attr}`}
          uiSchema={{
            "ui:readonly": false,
            "ui:widget": properties[attr].type,
            ...props.options[attr],
          }}
          onChange={(e) => {
            setAppropriateFieldValue({ [attr]: e }, num);
          }}
          label={properties[attr].title}
          value={getValue(attr)}
        />
      );
    }
    return render;
  };

  useEffect(() => {
    if (value.length === 0) {
      if (props.value !== 0) {
        setValue(props.value);
      }
    }
  }, [props.value]);

  return (
    <>
      {Array.from(Array(numOfFields).keys()).map((num) =>
        mapProperties(props.schema.items.properties, num)
      )}
    </>
  );
};

export default ObjectArrayWidget;
