import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import {
  deNormalizeResponse,
  formConfig,
  normalizeResponse,
  pageConfig,
} from "./operations.config";

const SingleOperation = () => {
  const api = useApi();
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});

  const onSubmit = async (data) => {
    const id =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    let payload = data.formData;
    payload = deNormalizeResponse(payload);
    if (id !== "novi") {
      try {
        await api.put(API_ROUTES[pageConfig.apiRoute].update, payload);
        history.goBack();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await api.post(API_ROUTES[pageConfig.apiRoute].add, payload);
        history.goBack();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const onChange = (data) => {
    return data;
  };

  React.useEffect(() => {
    const getSingle = async () => {
      let res = await api.get(
        `${API_ROUTES[pageConfig.apiRoute].single}?id=${id}`
      );
      setFormData(normalizeResponse(res));
      return res;
    };

    // TODO: DIFERENCE FROM OTHER PAGES
    const getSectors = async () => {
      let res = await api.get(`${API_ROUTES.sectors.all}`);
      let mapped = res.map((sector) => {
        return { label: sector.sector_name, value: sector.id };
      });
      formConfig.uiSchema.sector[`ui:options`].data = mapped;
      setSchema(formConfig.schema);
      setUiSchema(formConfig.uiSchema);
      return res;
    };

    const id =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (id !== "novi") {
      getSingle();
    }
    getSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SingleLayout>
      <Form
        className="last-child-full-width-form"
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={onSubmit}
        onChange={onChange}
        onCancel={onCancel}
      ></Form>
    </SingleLayout>
  );
};

export default SingleOperation;
