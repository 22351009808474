// import React from "react";
// import Table from "../../components/ui/table/table.component";
// import API_ROUTES from "../../layers/api/api.routes";
// import TableLayout from "../../layouts/table/table.layout";
// import useData from "../../services/useData";
import { useEffect, useState } from "react";
import Table from "../../components/ui/table/table.component";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import { formConfig, pageConfig } from "./related-products.config";
import "./related-products.styles.scss";

const RelatedProducts = () => {
  const api = useApi();
  const [activeProduct, setActiveProduct] = useState(undefined);
  const [formData, setFormData] = useState({});
  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [addSchema, setAddSchema] = useState({});
  const [addUiSchema, setAddUiSchema] = useState({});

  useEffect(() => {
    const getProducts = async () => {
      let res = await api.get(`${API_ROUTES.products.all}`);
      let mapped = res.map((product) => {
        return { label: product.product_name, value: product.id };
      });

      formConfig.dataUiSchema.product[`ui:options`].data = mapped;
      formConfig.addUiSchema.related_product[`ui:options`].data = mapped;

      setSchema(formConfig.dataSchema);
      setUiSchema(formConfig.dataUiSchema);

      setAddSchema(formConfig.addSchema);
      setAddUiSchema(formConfig.addUiSchema);
    };

    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addProduct = async (formData) => {
    const data = {
      product: activeProduct.id,
      ...formData,
    };
    let res = await api.post(`${API_ROUTES.relatedProducts.add}`, data);
    if (res.message === "Successfully created related product!") {
      handleProductFormChange({ formData: { product: activeProduct.id } });
      setFormData({});
    }
  };

  const handleDelete = async (id) => {
    await api.del(`${API_ROUTES.relatedProducts.delete}`, {
      parent_product: activeProduct.id,
      child_product: id,
    });
    handleProductFormChange({ formData: { product: activeProduct.id } });
  };
  const renderProductInfo = (activeProduct) => {
    if (!activeProduct) {
      return null;
    }
    return (
      <Table
        handleDelete={handleDelete}
        title="Povezani proizvodi"
        actions={[
          {
            name: "delete",
            order: 2,
          },
        ]}
        data={{
          original: activeProduct.related_products,
          display: [
            {
              name: "Naziv",
              render: "product_name",
            },
            {
              name: "Kolicina",
              render: "product_quantity",
            },
          ],
        }}
      />
    );
  };

  const handleProductFormChange = async (data) => {
    if (data.formData.product) {
      const productInfo = await api.get(
        `${API_ROUTES.products.single}?id=${data.formData.product}`
      );
      if (productInfo) {
        setActiveProduct(productInfo);
      }
    }
  };

  const handleFormChange = (data) => {
    setFormData(data.formData);
    return data;
  };

  return (
    <SingleLayout singleRoute={pageConfig.singlePageRoute}>
      <h4 className="mt-0">Odaberi proizvod</h4>
      <Form
        className="full-width-form"
        hideButtons
        schema={schema}
        uiSchema={uiSchema}
        onChange={handleProductFormChange}
      >
        {renderProductInfo(activeProduct)}
      </Form>
      <h4 className="mt-4">Dodaj proizvod</h4>
      <Form
        hideButtons
        formData={formData}
        schema={addSchema}
        uiSchema={addUiSchema}
        onSubmit={(e) => addProduct(e.formData)}
        onChange={handleFormChange}
      >
        <button className="ripple ripple-surface btn btn-primary mt-2">
          Dodaj povezani proizvod
        </button>
      </Form>
    </SingleLayout>
  );
};

export default RelatedProducts;
