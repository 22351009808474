const pageConfig = {
  apiRoute: "workers",
  singlePageRoute: "radnici",
  tableConfig: [
    {
      name: "Naziv",
      render: "worker_name",
    },
    {
      name: "Kod sektora",
      render: "sector/sector_name",
    },
  ],
  actionsConfig: [
    {
      name: "edit",
      order: 1,
    },
    {
      name: "delete",
      order: 2,
    },
  ],
  searchConfig: { keys: ["worker_name", "sector.sector_name"] },
  paginationConfig: {
    perPage: 10,
    count: 5,
    range: 3,
  },
};

const formConfig = {
  schema: {
    type: "object",
    required: ["worker_name"],
    properties: {
      number: {
        title: "Identifikacioni broj radnika",
        type: "string",
      },
      worker_name: {
        title: "Ime radnika",
        type: "string",
      },
      work_phase: {
        title: "Faza rada",
        type: "string",
      },
      work_subphase: {
        title: "Podfaza rada",
        type: "string",
      },
      active: {
        title: "Aktivan",
        type: "boolean",
      },
      uniq_ident_number: {
        title: "Unikatni identifikacioni broj",
        type: "string",
      },
      degree_of_education: {
        title: "Stepen obrazovanja",
        type: "string",
      },
      employment_time: {
        title: "Datum početka radnog odnosa",
        type: "string",
      },
      employment_break: {
        title: "Datum prestanka radnog odnosa",
        type: "string",
      },
      bank_account: {
        title: "Bankovni račun",
        type: "string",
      },
      sector: {
        title: "Sektor",
        type: "string",
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    employment_time: {
      "ui:widget": "date",
    },
    employment_break: {
      "ui:widget": "date",
    },
    sector: {
      "ui:widget": "customSelect",
      "ui:options": {
        mapData: {
          value: "id",
          label: "sector_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
};

const normalizeResponse = (res) => {
  res.active = res.active === "y" ? true : false;
  res.number = res.worker_number;
  delete res.worker_number;
  return res;
};

const deNormalizeResponse = (payload) => {
  payload.active = payload.active ? "y" : "n";
  return payload;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
