import React from "react";
import Table from "../../components/ui/table/table.component";
import API_ROUTES from "../../layers/api/api.routes";
import TableLayout from "../../layouts/table/table.layout";
import useData from "../../services/useData";
import { pageConfig } from "./master-errands.config";

const AllMasterErrands = () => {
  const { data, loadData } = useData(true, pageConfig.apiRoute);

  const handlePrint = (id) => {
    window.open(
      `http://dodic.ididit.mystableservers.com/api/generate-master-errand?id=${id}&case=PREVIEW`,
      "_blank"
    );
  };
  const handleDownload = (id) => {
    window.open(
      `http://dodic.ididit.mystableservers.com/api/generate-master-errand?id=${id}&case=PRINT`,
      "_blank"
    );
  };

  return (
    <TableLayout singleRoute={pageConfig.singlePageRoute}>
      <Table
        apiRoute={API_ROUTES[pageConfig.apiRoute]}
        singlePageRoute={pageConfig.singlePageRoute}
        postDelete={() => {
          loadData();
        }}
        handleDownload={handleDownload}
        handlePrint={handlePrint}
        searchConfig={pageConfig.searchConfig}
        paginationConfig={pageConfig.paginationConfig}
        data={{
          original: data,
          display: pageConfig.tableConfig,
        }}
        actions={pageConfig.actionsConfig}
        pagination={pageConfig.paginationConfig}
      />
    </TableLayout>
  );
};

export default AllMasterErrands;
