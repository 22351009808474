const pageConfig = {
  apiRoute: "productTypes",
  singlePageRoute: "tip-proizvoda",
  tableConfig: [
    {
      name: "Naziv",
      render: "type_name",
    },
  ],
  actionsConfig: [
    {
      name: "edit",
      order: 1,
    },
    {
      name: "delete",
      order: 2,
    },
  ],
  searchConfig: { keys: ["type_name"] },
  paginationConfig: {
    perPage: 10,
    count: 5,
    range: 3,
  },
};

const formConfig = {
  schema: {
    type: "object",
    required: ["type_name"],
    properties: {
      type_name: {
        type: "string",
        title: "Ime tipa proizvoda",
        minLength: 3,
      },
    },
  },
  uiSchema: {},
  "ui:order": ["*"],
};

const normalizeResponse = (res) => {
  res.name = res.brand;
  res.capacity = parseInt(res.capacity);
  res.length = parseInt(res.length);
  res.width = parseInt(res.width);
  res.height = parseInt(res.height);
  return res;
};

const deNormalizeResponse = (res) => {
  return res;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
