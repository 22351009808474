import React, { useState, useContext, createContext } from "react";

const initialState = {
  isLoading: false,
  startLoading: () => 0,
  finishLoading: () => 0,
};

const LoadingContext = createContext(initialState);

function useProvideLoading() {
  const [loadingCount, setLoadingCount] = useState(0);

  const startLoading = () => setLoadingCount((prevCount) => prevCount + 1);
  const finishLoading = () => setLoadingCount((prevCount) => prevCount - 1);

  return { isLoading: loadingCount > 0, startLoading, finishLoading };
}

export const LoadingProvider = ({ children }) => {
  const loading = useProvideLoading();
  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext(LoadingContext);
};
