import React, { useState, useContext, createContext } from "react";

const initialState = {
  hasError: false,
  errors: [],
};

const ErrorContext = createContext(initialState);

function useProvideError() {
  const [errors, setErrors] = useState([]);

  const setError = (errors) => {
    setErrors(errors);
  };

  const clearError = () => {
    setErrors([]);
  };

  return { hasErrors: errors.length > 0, errors, setError, clearError };
}

export const ErrorProvider = ({ children }) => {
  const errors = useProvideError();
  return (
    <ErrorContext.Provider value={errors}>{children}</ErrorContext.Provider>
  );
};

export const useError = () => {
  return useContext(ErrorContext);
};
