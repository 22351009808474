import { useLocation } from "react-router-dom";
import EditMasterErrand from "./edit-master-errands.page";
import NewMasterErrand from "./new-master-errands.page";

const SingleMasterErrands = () => {
  const location = useLocation();
  const id =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  if (id === "novi") {
    return <NewMasterErrand />;
  } else {
    return <EditMasterErrand />;
  }
};

export default SingleMasterErrands;
