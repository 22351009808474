import axios from "axios";
import { useCallback } from "react";
import { useError } from "./useError";

const useApi = () => {
  const { setError } = useError();

  const convertResponse = (response) => {
    console.debug("api - convertResponse", response);
    if (response.data) {
      if (response.data.data) {
        return response.data.data;
      }
    }

    if (response.config.url.indexOf("master-errand?id=")) {
      if (response.data) {
        return response.data;
      }
    }
    if (response.config.url.indexOf("add-master-errand")) {
      if (response.data) {
        return response.data;
      }
    }
    return {
      success: true,
    };
  };

  const handleResponse = useCallback(
    (response) => {
      console.debug("api - handleResponse", response);
      if (response.status === 200 || response.status === 201) {
        setError([]);
        return convertResponse(response);
      } else {
        return {
          errors: response.errors,
          message: response.message,
        };
      }
    },
    [setError]
  );

  const getErrorResponse = useCallback(
    (err) => {
      console.debug("api - getErrorResponse", err.response);
      if (err.response.data.errors) {
        const errors = [];
        for (const iterator in err.response.data.errors) {
          errors.push({
            name: iterator,
            message: err.response.data.errors[iterator],
          });
        }
        setError(errors);
      } else {
        const errors = [{ name: "server", message: "Uncaught error" }];
        setError(errors);
      }
    },
    [setError]
  );

  const post = useCallback(
    async (url, data) => {
      return axios
        .post(url, data)
        .then((response) => handleResponse(response))
        .catch((err) => getErrorResponse(err));
    },
    [handleResponse, getErrorResponse]
  );

  const patch = useCallback(
    async (url, data) => {
      return (axios.patch(url), data)
        .then((response) => handleResponse(response))
        .catch((err) => getErrorResponse(err));
    },
    [handleResponse, getErrorResponse]
  );

  const put = useCallback(
    async (url, data) => {
      return axios
        .put(url, data)
        .then((response) => handleResponse(response))
        .catch((err) => getErrorResponse(err));
    },
    [handleResponse, getErrorResponse]
  );

  const del = useCallback(
    async (url, data) => {
      return axios
        .delete(url, { data })
        .then((response) => handleResponse(response))
        .catch((err) => getErrorResponse(err));
    },
    [handleResponse, getErrorResponse]
  );

  const get = useCallback(
    async (url) => {
      return axios
        .get(url)
        .then((response) => handleResponse(response))
        .catch((err) => getErrorResponse(err));
    },
    [handleResponse, getErrorResponse]
  );

  return { post, put, patch, del, get };
};

export default useApi;
