import { useEffect, useState } from "react";
import InfoSection from "../../components/ui/info-section/info-section.component";
import Table from "../../components/ui/table/table.component";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useData from "../../services/useData";
import { normalizeResponse, pageConfig } from "./master-errands.config";

const viewConfig = [
  {
    type: "section",
    items: [
      { name: "Kupac", property: "mast_errand/partner/name" },
      { name: "Broj naloga", property: "mast_errand/mast_errand_number" },
      { name: "Kamion", property: "mast_errand/truck/brand" },
      { name: "Datum naloga", property: "mast_errand/delivery_date" },
      { name: "Status naloga", property: "mast_errand/status_name" },
    ],
  },
  { type: "table" },
];

const specialCaseViewConfig = [
  {
    type: "section",
    title: "Specijalka",
    items: [
      {
        name: "Specijalka",
        property: "mast_errand/mast_err_items[0]/special_case",
        transform: (data) => (data === 0 ? "Ne" : "Da"),
      },
      {
        name: "Specijalka 1",
        property: "mast_errand/mast_err_items[0]/spec_case_text_one",
      },
      {
        name: "Specijalka 2",
        property: "mast_errand/mast_err_items[0]/spec_case_text_two",
      },
      {
        name: "Specijalka 3",
        property: "mast_errand/mast_err_items[0]/spec_case_text_three",
      },
    ],
  },
];

const loadConfig = [
  {
    type: "section",
    title: "Paketi",
    items: [
      {
        name: "Ukupno paketa",
        property: "mast_errand/loads",
        transform: (data) => data.length,
      },
    ],
  },
];

const ViewMasterErrand = () => {
  const { data } = useData(true, pageConfig.apiRoute, normalizeResponse);
  const [errandData, setErrandData] = useState(undefined);

  useEffect(() => {
    setErrandData(data);
  }, [data]);

  const parseData = (property, item) => {
    let currentObj;
    let finalObj;
    const splitProperty = property.split("/");
    const getObjProp = (obj, prop) => {
      // it's an array
      if (prop.includes("[")) {
        const splitted = prop.split("[");
        const indexNumber = parseInt(splitted[1].replace("]", ""));
        const objectName = splitted[0];
        return obj[objectName][indexNumber];
      }
      if (obj[prop]) {
        return obj[prop];
      }
    };
    splitProperty.forEach((prop, index) => {
      if (currentObj === undefined) {
        currentObj = errandData;
      }
      if (currentObj !== undefined) {
        finalObj = getObjProp(currentObj, prop);
        currentObj = finalObj;
      }
    });
    if (item.transform) {
      return item.transform(currentObj);
    } else {
      return currentObj;
    }
  };

  const renderView = (vc) => {
    if (!errandData) {
      return;
    }
    if (errandData.length === 0) {
      return;
    }
    if (vc.type === "section") {
      return vc.items.map((item, key) => {
        return (
          <div
            key={key}
            className=""
            style={{
              padding: "0.5rem 1.4rem",
              fontSize: "0.9rem",
            }}
          >
            <p className="font-weight-bold mb-2">{item.name}</p>
            <p className="mb-0">{parseData(item.property, item)}</p>
          </div>
        );
      });
    }
  };

  return (
    <SingleLayout>
      <h6>Osnovne informacije</h6>
      <div className="d-flex flex-row justify-content-between border mb-4">
        {viewConfig.map((vc) => renderView(vc))}
      </div>
      <Table
        className="mb-4"
        title="Proizvodi"
        apiRoute={API_ROUTES[pageConfig.apiRoute]}
        singlePageRoute={pageConfig.singlePageRoute}
        data={{
          original: data.mast_errand?.mast_err_items,
          display: pageConfig.productsTableConfig,
        }}
      />
      <Table
        className="mb-4"
        title="Radni nalozi"
        apiRoute={API_ROUTES[pageConfig.apiRoute]}
        singlePageRoute={pageConfig.singlePageRoute}
        data={{
          original: data.errands?.data,
          display: pageConfig.errandsTableConfig,
        }}
      />
      <InfoSection config={specialCaseViewConfig} data={errandData} />
      <InfoSection config={loadConfig} data={errandData} />
    </SingleLayout>
  );
};

export default ViewMasterErrand;
