import {
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarNav,
  MDBNavbarToggler,
} from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { globalContext } from "../../store/global-store";
import { headerConfig, headerPermissions } from "../routes.config";

export default function Header() {
  const location = useLocation();
  const [showBasic, setShowBasic] = useState(false);
  const [activeLink, setActiveLink] = React.useState("");
  const { globalState } = useContext(globalContext);

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    if (path) {
      setActiveLink(path);
    }
  }, [location]);

  const checkPermission = (component, route) => {
    if (globalState.user) {
      if (globalState.user.privilege === "ADMINISTRATOR") {
        return component;
      }
      if (
        headerPermissions[globalState.user.privilege].includes(`${route.path}`)
      ) {
        return component;
      }
    }
    return null;
  };

  return (
    <MDBNavbar
      expand="xl"
      bgColor="light"
      sticky
      className="mb-0 shadow-none border-bottom"
    >
      <MDBContainer fluid>
        <MDBNavbarBrand href="/">
          <img
            src="https://ididit.in.rs/images/logo.png"
            height="30"
            alt=""
            loading="lazy"
          />
        </MDBNavbarBrand>
        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className="mr-auto mb-0 mb-lg-0">
            {headerConfig.map((route, key) =>
              checkPermission(
                <MDBNavbarItem
                  className={`nav-link ${
                    activeLink === route.path.split("/")[1] ? "active" : ""
                  } ${showBasic ? "mobile" : ""}`}
                  key={key}
                >
                  <Link
                    to={route.path}
                    onClick={() => {
                      setShowBasic(false);
                    }}
                  >
                    {route.name}
                  </Link>
                </MDBNavbarItem>,
                route
              )
            )}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
