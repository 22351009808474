import { Theme as Bootstrap4Theme } from "@rjsf/bootstrap-4";
import { withTheme } from "@rjsf/core";
import { useHistory, useLocation } from "react-router-dom";
import CheckboxWidget from "../checkbox-widget/checkbox.widget";
import ObjectArrayWidget from "../object-array/object-array.widget";
import SelectWidget from "../select/select.widget";
import TextWidget from "../text-widget/text.widget";
import "./form.scss";

function transformErrors(errors) {
  return errors.map((error) => {
    if (error.name === "pattern") {
      error.message = "Only digits are allowed";
    }
    return error;
  });
}

const widgets = {
  customSelect: SelectWidget,
  CheckboxWidget: CheckboxWidget,
  TextWidget: TextWidget,
  ObjectArrayWidget: ObjectArrayWidget,
};

const RJSForm = withTheme(Bootstrap4Theme);

const Form = (props) => {
  const history = useHistory();
  const location = useLocation();

  const onCancel = () => {
    history.push(`/${location.pathname.split("/")[1]}`);
  };

  return (
    <div className="rjsf-form">
      <RJSForm
        className={props.className}
        liveValidate={props.liveValidate}
        schema={props.schema}
        formData={props.formData}
        uiSchema={props.uiSchema}
        onChange={props.onChange}
        onSubmit={props.onSubmit}
        transformErrors={transformErrors}
        validate={props.validate}
        widgets={widgets}
        noValidate={props.disableValidation}
      >
        {props.children}
        <div
          className={`col d-flex justify-content-end ${
            props.hideButtons ? "d-none" : ""
          }`}
        >
          <button className="ripple ripple-surface btn btn-primary m-2">
            Sačuvaj
          </button>
          <div
            onClick={onCancel}
            className="ripple ripple-surface btn btn-danger m-2"
          >
            Otkaži
          </div>
        </div>
      </RJSForm>
    </div>
  );
};

export default Form;
