import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const SelectWidget = (props) => {
  const [values, setValues] = useState([]);
  const [internalValue, setInternalValue] = useState(undefined);

  useEffect(() => {
    setValues(props.options.data);
  }, [props]);

  useEffect(() => {
    if (internalValue === undefined && props.value !== undefined) {
      let defaultValue = {
        value: "",
        label: "",
      };
      if (props.options.findDefault && props.options.findDefault.enabled) {
        let filtered = props.options.data.filter((originalData) => {
          const selector =
            originalData[props.options.findDefault.defaultAccessor];
          return selector === props.value;
        });
        defaultValue = filtered[0];
      } else {
        for (const iterator in props.options.mapData) {
          const mapValue = props.options.mapData[iterator];
          defaultValue[iterator] = props.value[mapValue];
        }
      }
      setInternalValue(defaultValue);
      onChange(defaultValue);
    }
  }, [props.value]);

  const onChange = (e) => {
    if (e) {
      setInternalValue(e);
      if (props.options.isMulti) {
      } else {
        props.onChange(e.value);
      }
    }
  };

  return (
    <div className="mb-0">
      <label
        className="form-label font-weight-bold"
        style={{ color: "#4f4f4f" }}
      >
        {props.label}
        {props.required ? "*" : ""}
      </label>
      <Select
        components={animatedComponents}
        options={values}
        onChange={onChange}
        isMulti={props.options.isMulti}
        value={internalValue}
        closeMenuOnSelect={props.options.closeMenuOnSelect}
      />
    </div>
  );
};

export default SelectWidget;
