import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import API_ROUTES from "../layers/api/api.routes";
import useApi from "./useApi";

const useSubmit = (denormalize, apiRoute) => {
  const history = useHistory();
  const location = useLocation();
  const api = useApi();

  const submit = useCallback(
    async (data) => {
      const id =
        location.pathname.split("/")[location.pathname.split("/").length - 1];
      let payload = data.formData;
      payload = denormalize(payload);
      try {
        if (id !== "novi") {
          const res = await api.put(API_ROUTES[apiRoute].update, payload);
          if (res.success) {
            history.goBack();
          }
          if (res.message) {
            history.goBack();
          }
        } else {
          const res = await api.post(API_ROUTES[apiRoute].add, payload);
          if (res.success) {
            history.goBack();
          }
          if (res.message) {
            history.goBack();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [api, apiRoute, denormalize, history, location.pathname]
  );

  return { submit };
};

export default useSubmit;
