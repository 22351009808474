import { MDBCheckbox } from "mdb-react-ui-kit";

const CheckboxWidget = (props) => {
  const handleChange = (e) => {
    props.onChange(e.target.checked);
  };
  return (
    <>
      <MDBCheckbox
        name={props.label}
        value={""}
        checked={props.value}
        label={props.label}
        required={props.required}
        defaultChecked={props.value}
        onChange={handleChange}
      />
    </>
  );
};

export default CheckboxWidget;
