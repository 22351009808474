import Fuse from "fuse.js";
import { MDBInput, MDBTable, MDBTableHead } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import useApi from "../../../services/useApi";
import usePagination from "../../../services/usePagination";
import TableBodyComponent from "./components/table.body.component";
import "./table.styles.scss";

const Table = (props) => {
  const api = useApi();
  const history = useHistory();

  const [initialData, setInitialData] = useState([]);
  const [activeData, setActiveData] = useState([]);

  const [searchString, setSearchString] = useState("");
  const [activePage, setActivePage] = useState(0);

  const { paginate, numberOfPages } = usePagination(
    initialData,
    props.paginationConfig?.perPage
  );

  useEffect(() => {
    setInitialData(props.data.original);
  }, [props.data]);

  useEffect(() => {
    if (props.paginationConfig) {
      setActiveData(paginate(initialData));
    } else {
      setActiveData(initialData);
    }
  }, [initialData, props.paginationConfig, paginate]);

  const handlePageChange = async (e) => {
    setSearchString("");
    setActivePage(e.selected);
    setActiveData(paginate(initialData, e.selected));
  };

  useEffect(() => {}, [activeData]);

  const handleDataChange = (data, row, attr) => {
    setActiveData(data);
    if (props.onDataChange) {
      props.onDataChange(data, row, attr);
    }
  };

  const handleCustomAction = (e) => {
    if (props.handleCustomAction) {
      props.handleCustomAction(e);
    }
  };

  const handleSearch = (e) => {
    setActivePage(0);
    const searchString = e.target.value;
    setSearchString(searchString);
    // let formatedData;
    if (props.searchConfig) {
      if (searchString.length === 0) {
        setActiveData(initialData);
      } else {
        const fuse = new Fuse(props.data.original, props.searchConfig);
        const results = fuse
          .search(e.target.value)
          .map((result) => result.item);

        setActiveData(results);
      }
    }
  };

  const handleView = async (id) => {
    if (props.handleEdit) {
      props.handleView(id);
    } else {
      history.push(`/${props.singlePageRoute}/view/${id}`);
    }
  };

  const handleEdit = async (id) => {
    if (props.handleEdit) {
      props.handleEdit(id);
    } else {
      history.push(`/${props.singlePageRoute}/${id}`);
    }
  };

  const handleDelete = async (id) => {
    let confirm = window.confirm("Da li želite da obrišete?");
    if (confirm) {
      if (props.handleDelete) {
        props.handleDelete(id);
      } else {
        try {
          await api.del(props.apiRoute.delete, {
            id,
            _method: "DELETE",
          });
          props.postDelete();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const calculateColSpan = (type) => {
    if (type === "search") {
      if (props.paginationConfig && numberOfPages > 1) {
        return props.data.display.length;
      }
    }
    return props.data.display.length + 1;
  };

  return (
    <>
      {props.title && <h6>{props.title}</h6>}
      <MDBTable className={"border " + props.className} striped>
        <MDBTableHead>
          {props.topNavDisabled ? null : (
            <tr colSpan={props.data.display.length + 1} className="top-nav">
              {props.searchConfig && (
                <th colSpan={calculateColSpan("search")}>
                  <MDBInput
                    className="table-search"
                    label="Pretraga"
                    id="search"
                    type="text"
                    value={searchString}
                    onChange={handleSearch}
                  />
                </th>
              )}
              {props.paginationConfig && numberOfPages > 1 && (
                <th colSpan={1}>
                  <ReactPaginate
                    forcePage={activePage}
                    className="pagination mb-0"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={props.paginationConfig.range}
                    pageCount={numberOfPages}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </th>
              )}
            </tr>
          )}
          <tr>
            {props.data.display.map((header, key) => (
              <th key={key} scope="col">
                {header.name}
              </th>
            ))}
            {props.actions && <th>Akcija</th>}
          </tr>
        </MDBTableHead>
        <TableBodyComponent
          actions={props.actions}
          data={activeData}
          displayedData={props.data.display}
          headerSize={props.data.display.length}
          components={props.components}
          handleDownload={props.handleDownload}
          handlePrint={props.handlePrint}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleView={handleView}
          handleCustomAction={handleCustomAction}
          handleDataChange={handleDataChange}
        />
      </MDBTable>
    </>
  );
};

export default Table;
