const pageConfig = {
  apiRoute: "operations",
  singlePageRoute: "operacije",
  tableConfig: [
    {
      name: "Ime",
      render: "operation_name",
    },
    {
      name: "Kod",
      render: "operation_code",
    },
    {
      name: "Ime sektora",
      render: "sector/sector_name",
    },
    {
      name: "Kod sektora",
      render: "sector/sector_code",
    },
  ],
  actionsConfig: [
    {
      name: "edit",
      order: 1,
    },
    {
      name: "delete",
      order: 2,
    },
  ],
  searchConfig: {
    keys: [
      "operation_name",
      "operation_code",
      "sector.sector_name",
      "sector.sector_code",
    ],
  },
  paginationConfig: {
    perPage: 10,
    count: 5,
    range: 3,
  },
};

const formConfig = {
  schema: {
    type: "object",
    required: ["name", "code"],
    properties: {
      name: {
        title: "Ime operacije",
        type: "string",
        minLength: 3,
      },
      code: {
        title: "Kod operacije",
        type: "string",
        minLength: 3,
        maxLength: 3,
      },
      sector: {
        title: "Sektor",
        type: "string",
      },
    },
  },
  uiSchema: {
    sector: {
      "ui:widget": "customSelect",
      "ui:options": {
        mapData: {
          value: "id",
          label: "sector_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    "ui:order": ["*"],
  },
};

const normalizeResponse = (res) => {
  res.name = res.operation_name;
  res.code = res.operation_code;
  return res;
};

const deNormalizeResponse = (payload) => {
  payload.code = payload.code.toUpperCase();
  return payload;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
