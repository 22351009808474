import { useState } from "react";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import { formConfig, pageConfig } from "./worker-performance.config";
import "./worker-performance.styles.scss";

const WorkerPerformance = () => {
  const [formData, setFormData] = useState({});
  const [workerName, setWorkerName] = useState("");
  const [errandName, setErrandName] = useState("");
  const api = useApi();

  const checkData = async () => {
    if (formData.worker_id) {
      let res = await api.get(
        `${API_ROUTES.workers.single}?id=${formData.worker_id}`
      );
      if (res?.id) {
        setWorkerName(res.worker_name);
      }
    }
    if (formData.errand_id) {
      let res = await api.get(
        `${API_ROUTES.errands.single}?id=${formData.errand_id}`
      );
      if (res?.erand?.id) {
        setErrandName(res?.erand?.errand_number);
      }
    }
  };

  const handleSubmit = async () => {
    const res = await api.post(API_ROUTES[pageConfig.apiRoute].add, formData);
    if (res.message === "Successfully recorded worker performance!") {
      setWorkerName("");
      setErrandName("");
      setFormData({
        worker_id: "",
        errand_id: "",
      });
    }
  };

  return (
    <SingleLayout>
      <div className="flex-row" style={{ width: "400px" }}>
        <Form
          // className="short"
          hideButtons
          schema={formConfig.schema}
          uiSchema={formConfig.uiSchema}
          formData={formData}
          onChange={(e) => {
            setFormData(e.formData);
          }}
        />
        <div className="info-container">
          <p className="worker-name">{workerName}</p>
          <p>{errandName}</p>
        </div>
      </div>
      <button
        onClick={checkData}
        className="ripple ripple-surface btn btn-secondary m-2"
      >
        Učitaj
      </button>
      {workerName && errandName && (
        <button
          onClick={handleSubmit}
          className="ripple ripple-surface btn btn-primary m-2"
        >
          Sačuvaj
        </button>
      )}
    </SingleLayout>
  );
};

export default WorkerPerformance;
