import cloneDeep from "lodash.clonedeep";
import { useCallback, useEffect, useState } from "react";
const usePagination = (initialData, perPage) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const paginate = useCallback(
    (data, pageNum) => {
      const cloned = cloneDeep(data);
      const paginatedArray = [];
      if (cloned) {
        for (let i = 0; i < cloned.length; i += perPage) {
          const chunk = cloned.slice(i, i + perPage);
          paginatedArray.push(chunk);
        }
        if (pageNum) {
          return paginatedArray[pageNum];
        }
        return paginatedArray[activePage];
      }
      return [];
    },
    [activePage, perPage]
  );

  const calcNumOfPages = (length, perPage) => {
    return Math.ceil(length / perPage);
  };

  useEffect(() => {
    if (initialData) {
      if (initialData.length > 0) {
        if (perPage) {
          setNumberOfPages(calcNumOfPages(initialData.length, perPage));
        }
      }
    }
  }, [initialData, perPage]);

  return { paginate, numberOfPages, setActivePage };
};

export default usePagination;
