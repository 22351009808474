import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import InfoSection from "../../components/ui/info-section/info-section.component";
import Table from "../../components/ui/table/table.component";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import { formConfig, normalizeResponse, pageConfig } from "./cargo.config";

const viewConfig = [
  {
    type: "section",
    items: [
      { name: "Kupac", property: "master_err/partner/name" },
      { name: "Broj naloga", property: "master_err/mast_errand_number" },
      { name: "Datum naloga", property: "master_err/delivery_date" },
      { name: "Status naloga", property: "master_err/status_name" },
    ],
  },
];

const CargoManagement = () => {
  const api = useApi();
  const [loadId, setLoadId] = useState(undefined);
  const [live, setLive] = useState(true);
  const [selectedTruck, setSelectedTruck] = useState(undefined);

  const [loadData, setLoadData] = useState(undefined);

  const [truckSchema, setTruckSchema] = useState({});
  const [truckUiSchema, setTruckUiSchema] = useState({});

  const checkCargoId = (id) => {
    const getSingleMrn = async () => {
      let res = await api.get(`${API_ROUTES.trucks.load}?id=${id}`);
      const normalizedResponse = normalizeResponse(res);
      setLoadData(normalizedResponse);
    };
    const getTrucks = async () => {
      let res = await api.get(`${API_ROUTES.trucks.all}`);
      const normalizedResponse = normalizeResponse(res);
      let mapped = normalizedResponse.map((truck) => {
        return { label: truck.brand, value: truck.id };
      });

      formConfig.uiSchema.truck[`ui:options`].data = mapped;
      setTruckSchema(formConfig.schema);
      setTruckUiSchema(formConfig.uiSchema);
    };
    getSingleMrn();
    getTrucks();
  };

  const changeFormData = (e) => {
    setLoadId(e.formData.load_id);
    setLive(e.formData.live);
  };

  const submitExistingTruck = async () => {
    const payload = {
      load_id: loadId,
      truck_id: loadData.truck.id,
      _method: "PUT",
    };

    let res = await api.put(`${API_ROUTES.trucks.placeLoad}`, payload);
    if (res.message === "Successfully placed load in truck!") {
      window.alert("Proizvod dodat u kamion!");
      window.location.reload();
    }
  };

  useEffect(() => {
    if (loadData) {
      if (live) {
        if (loadData.placed_in_truck === 0) {
          submitExistingTruck();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  const submitTruckChange = async () => {
    const payload = {
      load_id: loadId,
      truck_id: selectedTruck,
      _method: "PUT",
    };
    let res = await api.put(`${API_ROUTES.trucks.placeLoad}`, payload);
    // TODO
    if (res.message === "Successfully placed load in truck!") {
      window.alert("Proizvod dodat u kamion!");
      window.location.reload();
    }
  };

  return (
    <SingleLayout>
      <Form
        className="column-full-width-form"
        hideButtons
        schema={{
          type: "object",
          properties: {
            live: {
              title: "Utovar uzivo",
              type: "boolean",
              default: live,
            },
            load_id: {
              title: "Utovar Id",
              type: "string",
            },
          },
        }}
        uiSchema={{
          "ui:order": ["*"],
        }}
        formData={{ live, load_id: loadId }}
        onChange={(e) => {
          if (live) {
            checkCargoId(e.formData.load_id);
          }
          changeFormData(e);
        }}
        disableValidation
      >
        <div className={`col d-flex justify-content-end align-items-center`}>
          {!loadData && (
            <button
              onClick={() => {
                checkCargoId(loadId);
              }}
              className="ripple ripple-surface btn btn-primary m-2"
            >
              Proveri utovar
            </button>
          )}
        </div>
      </Form>
      {!loadData && (
        <>
          <div style={{ width: "400px" }} className="qr-code-container">
            <QrReader
              constraints={{ facingMode: "environment" }}
              onResult={(result, error) => {
                if (!!result) {
                  setLoadId(result?.text);
                }
                if (!!error) {
                  console.info(error);
                }
              }}
            />
          </div>
        </>
      )}
      {loadData && (
        <div
          className="title-container mb-4"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h6 className="m-0">MRN INFO</h6>
          <button
            className="ripple ripple-surface btn btn-danger m-2"
            onClick={() => {
              setLoadId(undefined);
              setLoadData(undefined);
            }}
          >
            Skeniraj novi
          </button>
        </div>
      )}
      {loadData ? (
        <>
          <InfoSection config={viewConfig} data={loadData} />
          <Table
            className="mb-4"
            title="Proizvodi"
            apiRoute={API_ROUTES[pageConfig.apiRoute]}
            singlePageRoute={pageConfig.singlePageRoute}
            data={{
              original: loadData?.master_err?.mast_err_items,
              display: pageConfig.productsTableConfig,
            }}
          />
        </>
      ) : (
        <div className="d-flex flex-row justify-content-center border mb-4">
          Nema podataka, skenirajte QR KOD
        </div>
      )}

      {loadData && (
        <>
          <h6>Podaci o kamionu</h6>
          <div
            className={`col d-flex justify-content-start align-items-center`}
          >
            {loadData.placed_in_truck > 0 ? (
              <p className="mb-2 mr-2">
                Ovaj radni nalog se trenutno nalazi u kamionu pod nazivom{" "}
                <span className="font-weight-bold">
                  {loadData?.truck?.brand}
                </span>
                . Promenite kamion u opcijama ispod.
              </p>
            ) : (
              <div
                className={`col d-flex justify-content-between align-items-center`}
              >
                <p className="mb-0 mr-2">
                  Izabrani kamion naloga je {loadData?.truck?.brand}. Da li
                  želite da dodate proizvod u izabrani kamion?
                </p>
                <button
                  onClick={() => submitExistingTruck()}
                  className="ripple ripple-surface btn btn-primary m-2"
                >
                  Da
                </button>
              </div>
            )}
          </div>
        </>
      )}
      {loadData?.placed_in_truck > 0 && (
        <>
          <h6>Promeni kamion</h6>
          <Form
            hideButtons
            schema={truckSchema}
            uiSchema={truckUiSchema}
            formData={{}}
            onChange={(e) => {
              setSelectedTruck(e.formData.truck);
            }}
            disableValidation
          >
            {selectedTruck && (
              <div
                className={`col d-flex justify-content-end align-items-center`}
              >
                <p className="mb-0 mr-2">
                  Da li želite da dodate proizvod u izabrani kamion?
                </p>
                <button
                  onClick={submitTruckChange}
                  className="ripple ripple-surface btn btn-primary m-2"
                >
                  Da
                </button>
              </div>
            )}
          </Form>
        </>
      )}
    </SingleLayout>
  );
};

export default CargoManagement;
