const pageConfig = {
  apiRoute: "trucks",
  singlePageRoute: "kamioni",
  tableConfig: [
    {
      name: "Naziv",
      render: "brand",
    },
    {
      name: "Tip",
      render: "type",
    },
    {
      name: "Registracija",
      render: "registration_number",
    },
  ],
  actionsConfig: [
    {
      name: "edit",
      order: 1,
    },
    {
      name: "delete",
      order: 2,
    },
  ],
  searchConfig: {
    keys: ["brand", "type", "registration_number"],
  },
  paginationConfig: {
    perPage: 10,
    count: 5,
    range: 3,
  },
};

const formConfig = {
  schema: {
    type: "object",
    required: [
      "name",
      "type",
      "registration_number",
      "capacity",
      "length",
      "width",
      "height",
    ],
    properties: {
      name: {
        title: "Ime kamiona",
        type: "string",
        minLength: 3,
      },
      type: {
        title: "Tip kamiona",
        type: "string",
      },
      registration_number: {
        title: "Registracioni broj",
        type: "string",
        minLength: 3,
      },
      capacity: {
        title: "Kapacitet",
        type: "number",
      },
      length: {
        title: "Dužina",
        type: "number",
      },
      width: {
        title: "Širina",
        type: "number",
      },
      height: {
        title: "Visina",
        type: "number",
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
  },
};

const normalizeResponse = (res) => {
  res = res.truck;
  res.name = res.brand;
  res.capacity = parseInt(res.capacity);
  res.length = parseInt(res.length);
  res.width = parseInt(res.width);
  res.height = parseInt(res.height);
  return res;
};

const deNormalizeResponse = (payload) => {
  for (const property in payload) {
    payload[property] = payload[property].toString();
  }
  payload.brand = payload.name;
  delete payload.name;

  return payload;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
