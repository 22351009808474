import React from "react";
import Table from "../../components/ui/table/table.component";
import API_ROUTES from "../../layers/api/api.routes";
import TableLayout from "../../layouts/table/table.layout";
import useData from "../../services/useData";
import { pageConfig } from "./sectors.config";

const AllSectors = () => {
  const { data, loadData } = useData(true, pageConfig.apiRoute);
  return (
    <TableLayout singleRoute={pageConfig.singlePageRoute}>
      <Table
        apiRoute={API_ROUTES[pageConfig.apiRoute]}
        singlePageRoute={pageConfig.singlePageRoute}
        postDelete={() => {
          loadData();
        }}
        searchConfig={pageConfig.searchConfig}
        paginationConfig={pageConfig.paginationConfig}
        data={{
          original: data,
          display: pageConfig.tableConfig,
        }}
        actions={pageConfig.actionsConfig}
      />
    </TableLayout>
  );
};

export default AllSectors;
