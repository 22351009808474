import cloneDeep from "lodash.clonedeep";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "../../components/ui/table/table.component";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import useData from "../../services/useData";
import { normalizeResponse, pageConfig } from "./errands.config";

let formConfig = {
  schema: {
    type: "object",
    properties: {
      err_id: {
        title: "Broj radnog naloga",
        type: "string",
      },
      status: {
        title: "Status",
        type: "string",
      },
      sector: {
        title: "Sektor",
        type: "string",
      },
      delivery_date: {
        title: "Rok izrade",
        type: "string",
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    err_id: {
      "ui:readonly": true,
    },
    delivery_date: {
      "ui:widget": "date",
      type: "date",
    },
    status: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "value",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "status_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    sector: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "value",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "sector_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
};

let materialsFormConfig = {
  schema: {
    type: "object",
    properties: {
      products: {
        title: "Dodaj materijal",
        type: "string",
      },
      required_amount: {
        title: "Kolicina",
        type: "number",
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    products: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "value",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "brand",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
};

const viewConfig = [
  {
    type: "section",
    items: [
      { name: "Broj naloga", property: "errand_number" },
      { name: "Sektor", property: "sector_name" },
      { name: "Datum naloga", property: "delivery_date" },
    ],
  },
  { type: "table" },
];

const EditErrand = () => {
  const api = useApi();
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [materialsFormData, setMaterialsFormData] = useState({});
  const [materialsSchema, setMaterialsSchema] = useState({});
  const [materialsUiSchema, setMaterialsUiSchema] = useState({});
  const [changedData, setChangedData] = useState([]);
  const { data, loadData } = useData(
    true,
    pageConfig.apiRoute,
    normalizeResponse
  );
  const [errandData, setErrandData] = useState(undefined);
  const [product, setProductData] = useState([]);

  const handleSubmit = async () => {
    let splitDate = formData.delivery_date.split("-");
    const res = await api.put(API_ROUTES.errands.update, {
      err_id: data.id,
      partner: formData.partner,
      status: formData.status,
      sector: formData.sector,
      truck: formData.truck,
      delivery_date: `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`,
      _method: "PUT",
    });
    if (res.message === "Successfully updated errand!") {
      history.goBack();
    }
  };

  useEffect(() => {
    const getData = async () => {
      let sectors = await api.get(`${API_ROUTES.sectors.all}`);
      let mappedSectors = sectors.map((sector) => {
        return { label: sector.sector_name, value: sector.id };
      });

      let statuses = await api.get(`${API_ROUTES.status.all}`);
      let mappedStatuses = statuses.map((status) => {
        return { label: status.status_name, value: status.id };
      });

      let materials = await api.get(`${API_ROUTES.products.materials}`);
      let mappedMaterials = materials.map((material) => {
        return { label: material.product_name, value: material.id };
      });

      formConfig.uiSchema.sector[`ui:options`].data = mappedSectors;
      formConfig.uiSchema.status[`ui:options`].data = mappedStatuses;

      materialsFormConfig.uiSchema.products[`ui:options`].data =
        mappedMaterials;

      setSchema(formConfig.schema);
      setUiSchema(formConfig.uiSchema);
      setMaterialsSchema(materialsFormConfig.schema);
      setMaterialsUiSchema(materialsFormConfig.uiSchema);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const normalizeDate = (date) => {
      const splitted = date.split(".");
      if (splitted.length === 0) {
        return date;
      }
      return `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
    };
    if (data && data.length === undefined) {
      if (data?.product) {
        data.product.quantity = data.quantity;
        setProductData([data.product]);
      }
      setErrandData(data);
      setFormData({
        err_id: data.errand_number,
        status: data.status_id,
        sector: data.sector_id,
        delivery_date: normalizeDate(data.delivery_date),
      });
    }
  }, [data]);

  const handleFormChange = (formData) => {
    setFormData(formData);
  };

  const parseData = (property) => {
    let currentObj;
    let finalObj;
    const splitProperty = property.split("/");
    const getObjProp = (obj, prop) => {
      return obj[prop];
    };
    splitProperty.forEach((prop, index) => {
      if (currentObj === undefined) {
        currentObj = errandData;
      }
      finalObj = getObjProp(currentObj, prop);
      currentObj = finalObj;
    });
    return currentObj;
  };

  const renderView = (vc) => {
    if (!errandData) {
      return;
    }
    if (errandData.length === 0) {
      return;
    }
    if (vc.type === "section") {
      return vc.items.map((item, key) => (
        <div
          key={key}
          className=""
          style={{ padding: "0.5rem 1.4rem", fontSize: "0.9rem" }}
        >
          <p className="font-weight-bold mb-2">{item.name}</p>
          <p className="mb-0">{parseData(item.property)}</p>
        </div>
      ));
    }
  };

  return (
    <SingleLayout>
      <h6>Osnovne informacije</h6>
      <Form
        hideButtons
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={(e) => {
          handleFormChange(e.formData);
        }}
        disableValidation
      >
        <button
          onClick={handleSubmit}
          className="ripple ripple-surface btn btn-primary m-2"
        >
          Izmeni
        </button>
      </Form>
      <div className="d-flex flex-row justify-content-between border mb-4">
        {viewConfig.map((vc) => renderView(vc))}
      </div>
      <Table
        className="mb-4"
        title="Proizvodi"
        apiRoute={API_ROUTES[pageConfig.apiRoute]}
        singlePageRoute={pageConfig.singlePageRoute}
        data={{
          original: product,
          display: pageConfig.productsTableConfig,
        }}
      />

      <Table
        title="Materijali"
        apiRoute={API_ROUTES[pageConfig.apiRoute]}
        singlePageRoute={pageConfig.singlePageRoute}
        handleCustomAction={async (e) => {
          const getRow = changedData.filter((row) => row.id === e);
          if (getRow[0]) {
            await api.put(API_ROUTES.errands.editItem, {
              err_item_id: getRow[0].id,
              required_amount: getRow[0].required_amount,
              aditional_note: "",
              _method: "PUT",
            });
            window.alert("Promenjena količina materijala u radnom nalogu");
            loadData();
          }
        }}
        data={{
          original: data?.errand_items,
          display: pageConfig.materialsTableConfig,
        }}
        onDataChange={async (e, row, attr) => {
          const data = cloneDeep(changedData);
          let existing = false;
          data.forEach((dataRow) => {
            if (dataRow.id === row.id) {
              dataRow.required_amount = parseFloat(row.required_amount);
              existing = true;
            }
          });
          if (!existing) {
            data.push(row);
          }
          setChangedData(data);
        }}
        handleDelete={async (id) => {
          await api.del(API_ROUTES.errands.deleteItem, {
            err_item_id: id,
            _method: "delete",
          });
          loadData();
        }}
        actions={[
          {
            name: "delete",
            order: 1,
          },
          { name: "custom", order: 2, icon: "check" },
        ]}
      />
      <Form
        className="mt-4"
        hideButtons
        schema={materialsSchema}
        uiSchema={materialsUiSchema}
        formData={materialsFormData}
        onChange={(e) => {
          setMaterialsFormData(e.formData);
        }}
        disableValidation
      >
        <button
          onClick={async () => {
            await api.post(API_ROUTES.errands.addItem, {
              err_id: data.id,
              product_id: materialsFormData.products,
              required_amount: materialsFormData.required_amount,
              aditional_note: "",
            });
            window.alert("Materijal uspešno dodat.");
            loadData();
          }}
          className="ripple ripple-surface btn btn-primary m-2"
        >
          Sačuvaj
        </button>
      </Form>
    </SingleLayout>
  );
};

export default EditErrand;
