import { MDBIcon } from "mdb-react-ui-kit";
import "./floating.button.styles.scss";

const FloatingButton = (props) => {
  return (
    <MDBIcon
      onClick={() => props.onClick()}
      icon="plus-circle"
      className={"floating-button"}
    />
  );
};

export default FloatingButton;
