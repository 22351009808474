import { useEffect, useState } from "react";
import Table from "../../components/ui/table/table.component";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useData from "../../services/useData";
import { normalizeResponse, pageConfig } from "./errands.config";

const viewConfig = [
  {
    type: "section",
    items: [
      { name: "Broj naloga", property: "errand_number" },
      { name: "Sektor", property: "sector_name" },
      { name: "Datum naloga", property: "end_date" },
    ],
  },
  { type: "table" },
];

const ViewSingleErrand = () => {
  const { data } = useData(true, pageConfig.apiRoute, normalizeResponse);
  const [errandData, setErrandData] = useState(undefined);
  const [product, setProductData] = useState([]);

  useEffect(() => {
    if (data && data.length === undefined) {
      if (data.product) {
        data.product.quantity = data.quantity;
        setProductData([data.product]);
      }
      setErrandData(data);
    }
  }, [data]);

  const parseData = (property) => {
    let currentObj;
    let finalObj;
    const splitProperty = property.split("/");
    const getObjProp = (obj, prop) => {
      return obj[prop];
    };
    splitProperty.forEach((prop, index) => {
      if (currentObj === undefined) {
        currentObj = errandData;
      }
      finalObj = getObjProp(currentObj, prop);
      currentObj = finalObj;
    });
    return currentObj;
  };

  const renderView = (vc) => {
    if (!errandData) {
      return;
    }
    if (errandData.length === 0) {
      return;
    }
    if (vc.type === "section") {
      return vc.items.map((item, key) => (
        <div
          key={key}
          className=""
          style={{ padding: "0.5rem 1.4rem", fontSize: "0.9rem" }}
        >
          <p className="font-weight-bold mb-2">{item.name}</p>
          <p className="mb-0">{parseData(item.property)}</p>
        </div>
      ));
    }
  };

  return (
    <SingleLayout>
      <h6>Osnovne informacije</h6>
      <div className="d-flex flex-row justify-content-between border mb-4">
        {viewConfig.map((vc) => renderView(vc))}
      </div>
      <Table
        className="mb-4"
        title="Proizvodi"
        apiRoute={API_ROUTES[pageConfig.apiRoute]}
        singlePageRoute={pageConfig.singlePageRoute}
        data={{
          original: product,
          display: pageConfig.productsTableConfig,
        }}
      />
      <Table
        title="Materijali"
        apiRoute={API_ROUTES[pageConfig.apiRoute]}
        singlePageRoute={pageConfig.singlePageRoute}
        data={{
          original: data?.errand_items,
          display: [
            {
              name: "Ime ",
              render: "product/product_name",
            },
            {
              name: "Sifra",
              render: "product/barcode",
            },
            {
              name: "Kolicina",
              render: "required_amount",
            },
          ],
        }}
      />
    </SingleLayout>
  );
};

export default ViewSingleErrand;
