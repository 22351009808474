import { MDBInput } from "mdb-react-ui-kit";

const TextWidget = (props) => {
  const handleChange = (e) => {
    let value = e.target.value;
    if (props.options?.type === "number") {
      value = parseInt(e.target.value);
      if (props.options.min !== undefined) {
        if (value < props.options.min) {
          return;
        }
      }
      if (props.options.max !== undefined) {
        if (value > props.options.max) {
          return;
        }
      }
    }
    props.onChange(value);
  };

  const convertValue = (value) => {
    if (!value) {
      if (props.schema?.type === "number") {
        return 0;
      } else {
        return "";
      }
    }
    return value;
  };

  const getFieldType = () => {
    if (props.uiSchema["ui:widget"]) {
      return props.uiSchema["ui:widget"];
    }
    return props.schema?.type;
  };

  const isDisabled = () => {
    if (props.uiSchema["ui:readonly"]) {
      return props.uiSchema["ui:readonly"];
    }
    return false;
  };

  return (
    <>
      <label className="mb-1 font-weight-bold">{props.label}</label>
      <MDBInput
        disabled={isDisabled()}
        className="active"
        type={getFieldType()}
        step="any"
        required={props.required}
        value={convertValue(props.value)}
        onChange={handleChange}
      />
    </>
  );
};

export default TextWidget;
