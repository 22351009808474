const pageConfig = {
  apiRoute: "sectors",
  singlePageRoute: "sektori",
  tableConfig: [
    {
      name: "Ime sektora",
      render: "sector_name",
    },
    {
      name: "Kod sektora",
      render: "sector_code",
    },
  ],
  actionsConfig: [
    {
      name: "edit",
      order: 1,
    },
    {
      name: "delete",
      order: 2,
    },
  ],
  searchConfig: { keys: ["sector_code", "sector_name"] },
  paginationConfig: {
    perPage: 10,
    count: 5,
    range: 3,
  },
};

const formConfig = {
  schema: {
    type: "object",
    required: ["name", "code"],
    properties: {
      name: {
        title: "Ime sektora",
        type: "string",
        minLength: 3,
        classNames: "test",
      },
      code: {
        title: "Kod sektora",
        type: "string",
        minLength: 3,
        maxLength: 3,
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    name: { class: "customClassName" },
  },
};

const normalizeResponse = (res) => {
  res.name = res.sector_name;
  res.code = res.sector_code;
  return res;
};

const deNormalizeResponse = (payload) => {
  payload.code = payload.code.toUpperCase();
  return payload;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
