const pageConfig = {
  apiRoute: "errands",
  singlePageRoute: "rn",
  tableComponentConfig: {
    header: [
      {
        name: "Quantity",
        render: "quantity",
      },
    ],
    cells: [
      {
        attribute: "quantity",
        defaultValue: 0,
        component: (data) => (
          <input
            onChange={(e) => data.callback(e, data.attribute, data.row)}
            defaultValue={data.defaultValue}
            type="number"
          ></input>
        ),
      },
    ],
  },
  tableConfig: [
    {
      name: "Broj naloga",
      render: "errand_number",
    },
    {
      name: "Kolicina",
      render: "quantity",
    },
    {
      name: "Status",
      render: "status_name",
    },
  ],
  productsTableConfig: [
    {
      name: "Ime ",
      render: "product_name",
    },
    {
      name: "Sifra",
      render: "barcode",
    },
    {
      name: "Kolicina",
      render: "quantity",
    },
  ],
  materialsTableConfig: [
    {
      name: "Ime ",
      render: "product/product_name",
    },
    {
      name: "Sifra",
      render: "product/barcode",
    },
    {
      name: "Kolicina",
      render: "required_amount",
      component: {
        attribute: "required_amount",
        defaultValue: 1,
        render: (data) => (
          <input
            name={data.name}
            onChange={(e) => data.callback(e, data.attribute, data.row)}
            type="number"
            value={data.value}
          ></input>
        ),
      },
    },
  ],
  actionsConfig: [
    {
      name: "view",
      order: 1,
    },
    {
      name: "edit",
      order: 2,
    },
  ],
  searchConfig: { keys: ["errand_number", "status_name"] },
  paginationConfig: {
    perPage: 10,
    count: 5,
    range: 3,
  },
};

const formConfig = {
  schema: {
    type: "object",
    properties: {
      mast_err_number: { type: "string", minLength: 3 },
      partner: { type: "string" },
      delivery_date: { type: "string", minLength: 3 },
      products: {
        type: "string",
      },
      composition: {
        type: "string",
      },
    },
  },
  productTypeSchema: {
    type: "object",
    required: [""],
    properties: {
      variant: { type: "string", enum: ["A", "B", "C"] },
      specialCase: { type: "boolean", default: false },
    },

    dependencies: {
      specialCase: {
        oneOf: [
          {
            properties: {
              specialCase: { enum: [true] },
              spec_case_text_one: {
                type: "string",
              },
              spec_case_text_two: {
                type: "string",
              },
              spec_case_text_three: {
                type: "string",
              },
            },
          },
        ],
      },
      variant: {
        oneOf: [
          {
            properties: {
              variant: {
                enum: ["A"],
                value: "A",
              },
              "Kompletna presvlaka": {
                type: "string",
              },
            },
          },
          {
            properties: {
              variant: {
                enum: ["B"],
              },
              "Korpus i nasloni": {
                type: "string",
              },
              "Sedalni deo": {
                type: "string",
              },
            },
          },
          {
            properties: {
              variant: {
                enum: ["C"],
              },
              Korpus: {
                type: "string",
              },
              "Sedalni deo": {
                type: "string",
              },
              Glavonaslon: {
                type: "string",
              },
            },
          },
        ],
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    delivery_date: {
      "ui:widget": "date",
    },
    products: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    partner: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
  productTypeUiSchema: {
    "ui:order": [
      "*",
      "specialCase",
      "spec_case_text_one",
      "spec_case_text_two",
      "spec_case_text_three",
    ],
    "Kompletna presvlaka": {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    "Korpus i nasloni": {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    Korpus: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    "Sedalni deo": {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    Glavonaslon: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
};

const normalizeResponse = (res) => {
  res.erand.delivery_date = res.delivery_date;
  res = res.erand;
  return res;
};

const deNormalizeResponse = (res) => {
  if (!res.special_case) {
    res.products[0].spec_case_text_one = "";
    res.products[0].spec_case_text_two = "";
    res.products[0].spec_case_text_three = "";
  }
  return res;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
