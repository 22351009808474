import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import InfoSection from "../../components/ui/info-section/info-section.component";
import Table from "../../components/ui/table/table.component";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import { normalizeResponse, pageConfig } from "./cargo.config";

const viewConfig = [
  {
    type: "section",
    items: [
      { name: "Kupac", property: "master_err/partner/name" },
      { name: "Broj naloga", property: "master_err/mast_errand_number" },
      { name: "Datum naloga", property: "master_err/delivery_date" },
      { name: "Status naloga", property: "master_err/status_name" },
    ],
  },
];

const RemoveCargo = () => {
  const api = useApi();

  const [loadId, setLoadId] = useState(undefined);
  const [workerId, setWorkerId] = useState(
    "cb8543ac-bfe7-4071-8e4d-1b8714ee238f"
  );
  const [live, setLive] = useState(true);

  const [loadData, setLoadData] = useState(undefined);

  const checkCargoId = (id) => {
    const getSingleMrn = async () => {
      let res = await api.get(`${API_ROUTES.trucks.load}?id=${id}`);
      const normalizedResponse = normalizeResponse(res);
      setLoadData(normalizedResponse);
    };
    if (id) {
      getSingleMrn();
    }
  };

  const changeFormData = (e) => {
    setLoadId(e.formData.load_id);
    setWorkerId(e.formData.worker_id);
    setLive(e.formData.live);
  };

  const submitExistingTruck = async () => {
    const payload = {
      load_id: loadId,
      worker_id: workerId,
    };

    let res = await api.post(`${API_ROUTES.trucks.unload}`, payload);
    if (res.message === "Successfully unloaded load!") {
      setLoadData(undefined);
      setLoadId(undefined);
    }
  };

  useEffect(() => {
    if (loadData) {
      if (live) {
        if (loadData.placed_in_truck > 1) {
          submitExistingTruck();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  return (
    <SingleLayout>
      <Form
        className="column-full-width-form"
        hideButtons
        schema={{
          type: "object",
          properties: {
            live: {
              title: "Istovar uzivo",
              type: "boolean",
              default: live,
            },
            worker_id: {
              title: "Id radnika",
              type: "string",
            },
            load_id: {
              title: "Istovar Id",
              type: "string",
            },
          },
        }}
        uiSchema={{
          "ui:order": ["*"],
        }}
        formData={{ live, load_id: loadId, worker_id: workerId }}
        onChange={(e) => {
          if (live) {
            checkCargoId(e.formData.load_id);
          }
          changeFormData(e);
        }}
        disableValidation
      >
        <div className={`col d-flex justify-content-end align-items-center`}>
          {!loadData && (
            <button
              onClick={() => {
                checkCargoId(loadId);
              }}
              className="ripple ripple-surface btn btn-primary m-2"
            >
              Proveri istovar
            </button>
          )}
        </div>
      </Form>
      {!loadData && (
        <>
          <div style={{ width: "400px" }} className="qr-code-container">
            <QrReader
              constraints={{ facingMode: "environment" }}
              onResult={(result, error) => {
                if (!!result) {
                  setLoadId(result?.text);
                }
              }}
            />
          </div>
        </>
      )}
      {loadData && (
        <div
          className="title-container mb-4"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h6 className="m-0">MRN INFO</h6>
          <button
            className="ripple ripple-surface btn btn-danger m-2"
            onClick={() => {
              setLoadId(undefined);
              setLoadData(undefined);
            }}
          >
            Skeniraj novi
          </button>
        </div>
      )}
      {loadData ? (
        <>
          <InfoSection config={viewConfig} data={loadData} />
          <Table
            className="mb-4"
            title="Proizvodi"
            apiRoute={API_ROUTES[pageConfig.apiRoute]}
            singlePageRoute={pageConfig.singlePageRoute}
            data={{
              original: loadData?.master_err?.mast_err_items,
              display: pageConfig.productsTableConfig,
            }}
          />
        </>
      ) : (
        <div className="d-flex flex-row justify-content-center border mb-4">
          Nema podataka, skenirajte QR KOD
        </div>
      )}

      {loadData && (
        <>
          <h6>Podaci o kamionu</h6>
          <div
            className={`col d-flex flex-column justify-content-start align-items-start`}
          >
            {loadData.placed_in_truck > 0 && (
              <>
                <div
                  className={`col d-flex justify-content-between align-items-center`}
                >
                  <p className="mb-0 mr-2">
                    Izabrani kamion naloga je {loadData?.truck?.brand}. Da li
                    želite da istovarite proizvod iz kamiona?
                  </p>
                  <button
                    onClick={() => submitExistingTruck()}
                    className="ripple ripple-surface btn btn-primary m-2"
                  >
                    Da
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </SingleLayout>
  );
};

export default RemoveCargo;
