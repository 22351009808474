import { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { globalContext } from "../../store/global-store";

const Logout = () => {
  const { dispatch } = useContext(globalContext);
  useEffect(() => {
    dispatch({ type: "SET_USER", payload: undefined });
  }, [dispatch]);
  return <Redirect to="/" />;
};

export default Logout;
