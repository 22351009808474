import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API_ROUTES from "../layers/api/api.routes";
import useApi from "./useApi";

const useData = (onMount, apiRoute, normalize) => {
  const api = useApi();
  const location = useLocation();
  const [data, setData] = useState([]);

  const loadData = useCallback(() => {
    let id = location.pathname.split("/")[2];
    if (id === "view") {
      id = location.pathname.split("/")[3];
    }
    const getSingle = async () => {
      const res = await api.get(`${API_ROUTES[apiRoute].single}?id=${id}`);
      setData(normalize(res));
    };

    const getData = async () => {
      const res = await api.get(API_ROUTES[apiRoute].all);
      setData(res);
    };
    if (id === undefined) {
      getData();
    } else {
      if (id !== "novi") {
        getSingle();
      }
    }
  }, [api, apiRoute, location.pathname, normalize]);

  useEffect(() => {
    if (onMount) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loadData };
};

export default useData;
