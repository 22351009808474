const pageConfig = {
  apiRoute: "workerPerformance",
  singlePageRoute: "sektori",
  tableConfig: [
    {
      name: "Ime sektora",
      render: "sector_name",
    },
    {
      name: "Kod sektora",
      render: "sector_code",
    },
  ],
  actionsConfig: [
    {
      name: "edit",
      order: 1,
    },
    {
      name: "delete",
      order: 2,
    },
  ],
  searchConfig: { keys: ["sector_code", "sector_name"] },
  paginationConfig: {
    perPage: 10,
    count: 5,
    range: 3,
  },
};

const formConfig = {
  schema: {
    type: "object",
    required: ["worker_id", "errand_id"],
    properties: {
      worker_id: {
        title: "Identifikacioni broj radnika",
        type: "string",
        minLength: 3,
      },
      errand_id: {
        title: "Identifikacioni broj radnog naloga radnika",
        type: "string",
        minLength: 3,
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
  },
};

const normalizeResponse = (res) => {
  return res;
};

const deNormalizeResponse = (payload) => {
  return payload;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
