import "./mini-table.styles.scss";

const MiniTable = (props) => {
  const getColumns = (row) => {
    if (row) {
      const array = [];
      for (const iterator of props.config) {
        const path = iterator.render.split("/");
        if (iterator.component) {
          array.push(
            iterator.component.render({
              name: iterator.component.attribute,
              attribute: iterator.component.attribute,
              callback: () => {},
              row,
              value: row[iterator.render] ? row[iterator.render] : 1,
            })
          );
        } else {
          if (row.hasOwnProperty(iterator.render)) {
            array.push(row[iterator.render]);
          }
          if (path.length === 2) {
            if (row[path[0]]) {
              if (row[path[0]][path[1]]) {
                array.push(row[path[0]][path[1]]);
              }
            }
          }
        }
      }
      return array;
    }
  };

  return (
    <div className="mini-table">
      <div className="mini-table-row mini-table-header">
        {props.config.map((header, key) => (
          <div className="mini-table-cell" key={key}>
            {header.name}
          </div>
        ))}
      </div>
      <div className="mini-table-data">
        {props.data.map((row, key) => {
          return (
            <div className="mini-table-row" key={key}>
              {getColumns(row).map((column, key) => {
                return (
                  <div className="mini-table-cell" key={key}>
                    {column}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MiniTable;
