const pageConfig = {
  apiRoute: "masterErrands",
  singlePageRoute: "mrni",
  tableConfig: [
    {
      name: "Broj naloga",
      render: "mast_errand_number",
    },

    {
      name: "Delivery date",
      render: "delivery_date",
    },
  ],
  productsTableConfig: [
    {
      name: "Ime ",
      render: "product/product_name",
    },
    {
      name: "Sifra",
      render: "product/barcode",
    },
    {
      name: "Kolicina",
      render: "quantity",
    },
  ],
  errandsTableConfig: [
    {
      name: "Broj naloga",
      render: "errand_number",
    },
    {
      name: "Sifra",
      render: "bar_code",
    },
    {
      name: "Kolicina",
      render: "quantity",
    },
  ],
  selectedProductsConfig: [
    {
      name: "Naziv",
      render: "product_name",
    },
    {
      name: "Kod",
      render: "code",
    },
    {
      name: "Bar kod",
      render: "barcode",
    },
    {
      name: "Kolicina",
      render: "quantity",
      component: {
        attribute: "quantity",
        defaultValue: 1,
        render: (data) => (
          <input
            name={data.name}
            onChange={(e) => data.callback(e, data.attribute, data.row)}
            type="number"
            value={data.value}
          ></input>
        ),
      },
    },
  ],
  selectedProductsActions: [],
  actionsConfig: [
    {
      name: "view",
      order: 1,
    },
    {
      name: "edit",
      order: 2,
    },
    { name: "print", order: 3 },
    { name: "download", order: 4 },
  ],
  searchConfig: { keys: ["mast_errand_number", "delivery_date"] },
  paginationConfig: {
    perPage: 10,
    count: 5,
    range: 3,
  },
};

const formConfig = {
  schema: {
    type: "object",
    properties: {
      mast_err_number: {
        title: "Broj master radnog naloga",
        type: "string",
        minLength: 3,
      },
      mast_err_numb_origin: {
        title: "Originalni broj naloga",
        type: "string",
      },
      partner: {
        title: "Partner",
        type: "string",
      },
      truck: {
        title: "Kamion",
        type: "string",
      },
      delivery_date: {
        title: "Rok izrade",
        type: "string",
      },
      products: {
        title: "Proizvodi",
        type: "string",
      },
      furn_legs: {
        title: "Nogice",
        type: "string",
      },
      composition: {
        title: "Sastav",
        type: "string",
      },
    },
  },
  productTypeSchema: {
    type: "object",
    required: [""],
    properties: {
      variant: {
        title: "Varijanta",
        type: "string",
        enum: ["A", "B", "C"],
      },
      specialCase: {
        title: "Specijalni slučaj",
        type: "boolean",
        default: false,
      },
    },
    dependencies: {
      specialCase: {
        oneOf: [
          {
            properties: {
              specialCase: { enum: [true] },
              spec_case_text_one: {
                title: "Specijalni slučaj broj 1",
                type: "string",
              },
              spec_case_text_two: {
                title: "Specijalni slučaj broj 2",

                type: "string",
              },
              spec_case_text_three: {
                title: "Specijalni slučaj broj 3",
                type: "string",
              },
            },
          },
          {
            properties: {
              specialCase: { enum: [false] },
            },
          },
        ],
      },
      variant: {
        oneOf: [
          {
            properties: {
              variant: {
                enum: ["A"],
                value: "A",
              },
              "Kompletna presvlaka": {
                type: "string",
              },
            },
          },
          {
            properties: {
              variant: {
                enum: ["B"],
              },
              "Korpus i nasloni": {
                type: "string",
              },
              "Sedalni deo": {
                type: "string",
              },
            },
          },
          {
            properties: {
              variant: {
                enum: ["C"],
              },
              Korpus: {
                type: "string",
              },
              "Sedalni deo": {
                type: "string",
              },
              Glavonaslon: {
                type: "string",
              },
            },
          },
        ],
      },
    },
  },
  uiSchema: {
    "ui:order": ["*"],
    mast_err_number: {
      "ui:readonly": true,
    },
    delivery_date: {
      "ui:widget": "date",
      type: "date",
    },
    products: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    furn_legs: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },

    truck: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "brand",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    partner: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
  productTypeUiSchema: {
    "ui:order": [
      "*",
      "specialCase",
      "spec_case_text_one",
      "spec_case_text_two",
      "spec_case_text_three",
    ],
    "Kompletna presvlaka": {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    "Korpus i nasloni": {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    Korpus: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    "Sedalni deo": {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
    Glavonaslon: {
      "ui:widget": "customSelect",
      "ui:options": {
        findDefault: {
          enabled: true,
          defaultAccessor: "label",
          defaultValue: "id",
        },
        mapData: {
          value: "id",
          label: "product_name",
        },
        isMulti: false,
        closeMenuOnSelect: true,
        data: [],
      },
    },
  },
};

const normalizeResponse = (res) => {
  return res;
};

const deNormalizeResponse = (res) => {
  const date = res.delivery_date.split("-");
  res.delivery_date = `${date[1]}/${date[2]}/${date[0]}`;
  if (!res.products[0].special_case) {
    res.products[0].spec_case_text_one = "";
    res.products[0].spec_case_text_two = "";
    res.products[0].spec_case_text_three = "";
  }
  if (res.products[0].special_case) {
    res.products[0].spec_case_text_one = res.products[0].spec_case_text_one
      ? res.products[0].spec_case_text_one
      : "/";
    res.products[0].spec_case_text_two = res.products[0].spec_case_text_two
      ? res.products[0].spec_case_text_two
      : "/";
    res.products[0].spec_case_text_three = res.products[0].spec_case_text_three
      ? res.products[0].spec_case_text_three
      : "/";
  }
  return res;
};

export { pageConfig, formConfig, normalizeResponse, deNormalizeResponse };
