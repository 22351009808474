import { useEffect, useState } from "react";
import MiniTable from "../../components/ui/mini-table/mini-table.component";
import Form from "../../components/widgets/_form/form";
import API_ROUTES from "../../layers/api/api.routes";
import SingleLayout from "../../layouts/single/single.layout";
import useApi from "../../services/useApi";
import { formConfig, normalizeResponse } from "./cargo.config";

const ViewCargo = () => {
  const api = useApi();
  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [selectedTruck, setSelectedTruck] = useState(undefined);
  const [truckData, setTruckData] = useState(undefined);

  useEffect(() => {
    const getTrucks = async () => {
      let res = await api.get(`${API_ROUTES.trucks.all}`);
      const normalizedResponse = normalizeResponse(res);
      let mapped = normalizedResponse.map((truck) => {
        return { label: truck.brand, value: truck.id };
      });
      formConfig.uiSchema.truck[`ui:options`].data = mapped;
      setSchema(formConfig.schema);
      setUiSchema(formConfig.uiSchema);
    };
    getTrucks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getSingleTruck = async () => {
      let res = await api.get(
        `${API_ROUTES.trucks.single}?id=${selectedTruck}`
      );
      setTruckData(res);
    };
    if (selectedTruck) {
      getSingleTruck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTruck]);

  const calculateVolume = () => {
    let capacity = truckData.truck.capacity;
    let cargoFilled = 0;
    truckData.cargo.forEach((item) => {
      cargoFilled += parseFloat(item.m_3);
    });
    return (
      <div className="d-flex flex-row">
        <p
          className="font-weight-bold mb-0 mr-2"
          style={{ marginRight: "10px" }}
        >
          Popunjen: {((capacity - cargoFilled) / capacity) * 100}%
        </p>
        <p className="font-weight-bold m-0">
          Ostalo: {capacity - cargoFilled}m3
        </p>
      </div>
    );
  };
  return (
    <SingleLayout>
      <Form
        hideButtons
        schema={schema}
        uiSchema={uiSchema}
        formData={{}}
        onChange={(e) => {
          setSelectedTruck(e.formData.truck);
        }}
        disableValidation
      ></Form>
      {truckData && (
        <>
          <div
            className="title-container mb-2"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h6 className="m-0">Pregled kamiona</h6>
            {calculateVolume()}
          </div>
          <div className="split-view first">
            <div className="border view">
              <p className="d-flex flex-row justify-content-start  border mb-0 p-1">
                Ime kamiona:{" "}
                <span className="font-weight-bold">
                  {" "}
                  {truckData.truck.brand}
                </span>
              </p>
              <MiniTable
                config={[
                  {
                    name: "Pozicija ",
                    render: "position",
                  },
                  {
                    name: "Ime",
                    render: "product_name",
                  },
                  {
                    name: "Broj paketa",
                    render: "num_of_package",
                  },
                  {
                    name: "Broj naloga",
                    render: "mast_err_number",
                  },
                ]}
                data={truckData.cargo}
              />
            </div>
            <div className="border view second">
              <div className="column">
                {truckData.cargo
                  .sort((a, b) => a.position - b.position)
                  .map((cargo, key) => {
                    if (cargo.position % 2 !== 0) {
                      if (cargo.position === 0) {
                        return null;
                      }
                      return (
                        <div key={key} className="truck-div border">
                          <p>{cargo.position}</p>
                          <p className="mb-0">
                            {cargo.product_name} - {cargo.num_of_package}
                          </p>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
              <div className="column" style={{ minHeight: "400px" }}>
                {truckData.cargo
                  .sort((a, b) => a.position - b.position)
                  .map((cargo, key) => {
                    if (cargo.position === 0) {
                      return null;
                    }
                    if (cargo.position % 2 === 0) {
                      return (
                        <div key={key} className="truck-div border">
                          <p>{cargo.position}</p>
                          <p className="mb-0">
                            {cargo.product_name} - {cargo.num_of_package}
                          </p>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
          </div>
        </>
      )}
    </SingleLayout>
  );
};

export default ViewCargo;
