import CargoManagement from "../pages/cargo/add";
import RemoveCargo from "../pages/cargo/remove";
import ViewCargo from "../pages/cargo/view-cargo.page";
import Dashboard from "../pages/dashboard/dashboard";
import AllErrands from "../pages/errands/all-errands.page";
import EditErrand from "../pages/errands/edit-single-errand.page";
import ViewSingleErrand from "../pages/errands/view-single-errand.page";
import Logout from "../pages/logout/logout.page";
import AllMasterErrands from "../pages/master-orders/all-master-errands.page";
import SingleMasterErrands from "../pages/master-orders/single-master-errands.page";
import ViewMasterErrand from "../pages/master-orders/view-master-errands.page";
import AllOperations from "../pages/operations/all-operations.page";
import SingleOperation from "../pages/operations/single-operations.page";
import AllProducts from "../pages/products/all-products";
import SingleProducts from "../pages/products/single-products";
import AllProductTypes from "../pages/product_type/all-product-types.page";
import SingleProductTypes from "../pages/product_type/single-product-types.page";
import RelatedProducts from "../pages/related-products/related-products";
import AllSectors from "../pages/sector/all-sectors.page";
import SingleSector from "../pages/sector/single-sectors.page";
import AllTrucks from "../pages/trucks/all-trucks.page";
import SingleTrucks from "../pages/trucks/single-trucks.page";
import WorkerPerformance from "../pages/worker-performance/worker-performance.page";
import AllWorkers from "../pages/workers/all-workers.page";
import SingleWorkers from "../pages/workers/single-workers.page";

const routesConfig = [
  {
    path: "/proizvodi",
    component: AllProducts,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Proizvodi",
      order: 10,
    },
  },
  {
    path: "/proizvodi/:id",
    component: SingleProducts,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "Proizvodi",
      order: 0,
    },
  },
  {
    path: "/tip-proizvoda",
    component: AllProductTypes,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Tip proizvoda",
      order: 20,
    },
  },
  {
    path: "/tip-proizvoda/:id",
    component: SingleProductTypes,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "Tip proizvoda",
    },
  },
  {
    path: "/radnici",
    component: AllWorkers,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Radnici",
      order: 40,
    },
  },
  {
    path: "/radnici/:id",
    component: SingleWorkers,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "Radnici",
      order: 0,
    },
  },
  {
    path: "/kamioni",
    component: AllTrucks,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Kamioni",
      order: 70,
    },
  },
  {
    path: "/kamioni/:id",
    component: SingleTrucks,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "Kamioni",
      order: 0,
    },
  },
  {
    path: "/sektori",
    component: AllSectors,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Sektori",
      order: 50,
    },
  },
  {
    path: "/sektori/:id",
    component: SingleSector,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "Sektori",
      order: 0,
    },
  },
  {
    path: "/operacije",
    component: AllOperations,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Operacije",
      order: 60,
    },
  },
  {
    path: "/operacije/:id",
    component: SingleOperation,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "Operacije",
      order: 0,
    },
  },
  {
    path: "/mrni",
    component: AllMasterErrands,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "MRN",
      order: 0,
    },
  },
  {
    path: "/mrni/:id",
    component: SingleMasterErrands,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "MRN",
      order: 0,
    },
  },
  {
    path: "/mrni/view/:id",
    component: ViewMasterErrand,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "MRN",
      order: 0,
    },
  },
  {
    path: "/worker-performance",
    component: WorkerPerformance,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Učinak radnika",
      order: 80,
    },
  },
  {
    path: "/related-products",
    component: RelatedProducts,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Povezani proizodi",
      order: 30,
    },
  },
  {
    path: "/rn",
    component: AllErrands,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Radni nalozi",
      order: 1,
    },
  },
  {
    path: "/rn/view/:id",
    component: ViewSingleErrand,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "Radni nalozi",
      order: 0,
    },
  },
  {
    path: "/rn/:id",
    component: EditErrand,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "Radni nalozi",
      order: 0,
    },
  },
  {
    path: "/utovar",
    component: CargoManagement,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Utovar",
      order: 110,
    },
  },
  {
    path: "/pregled-utovara",
    component: ViewCargo,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Pregled Utovara",
      order: 120,
    },
  },
  {
    path: "/istovar",
    component: RemoveCargo,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Istovar",
      order: 130,
    },
  },
  {
    path: "/logout",
    component: Logout,
    exact: true,
    headerOptions: {
      display: true,
      displayName: "Izloguj se",
      order: 140,
    },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
    headerOptions: {
      display: false,
      displayName: "Dashboard",
      order: 0,
    },
  },
];

const headerConfig = routesConfig
  .filter((route) => route.headerOptions.display)
  .map((route) => {
    return {
      path: route.path,
      name: route.headerOptions.displayName,
      order: route.headerOptions.order,
    };
  })
  .sort((a, b) => a.order - b.order);

const headerPermissions = {
  ADMINISTRATOR: ["*"],
  UTOVAR: ["/utovar", "/pregled-utovara", "/logout"],
  ISTOVAR: ["/istovar", "/pregled-istovara", "/logout"],
  UCINAK: ["/worker-performance", "/logout"],
};
export { routesConfig, headerConfig, headerPermissions };
